<script setup lang="ts">
import {MediaListItemFragment} from "../graphql/types"
import {computed, toRefs} from "vue"
import {useMediaListContext} from "./MediaListContext"
import {toHumanReadableSize} from "../size"
import {mdiFileImageOutline, mdiFileOutline, mdiFileVideoOutline} from "@mdi/js"
import SvgIcon from "@jamescoyle/vue-icon"
import Tag from "../components/Tag.vue"
import {Selection} from "./select";

const props = defineProps<{
  item: MediaListItemFragment
  index: number
  order?: number
  isSelected: boolean
}>()

const emit = defineEmits<{
  (e: 'select', selection: Selection): void
}>()

const {item} = toRefs(props)

const {scale, view} = useMediaListContext()

const isListView = computed(() => 'list' === view.value)

const figureLayoutClasses = computed(() => {
  if ('list' === view.value) {
    return 'flex items-center gap-2'
  } else {
    return ''
  }
})

const imageLayoutClasses = computed(() => {
  if ('list' === view.value) {
    switch (scale.value) {
      case "1":
        return 'w-12 h-8'
      case "2":
        return 'w-16 h-12'
      case "4":
        return 'w-48 h-32'
      case "5":
        return 'w-64 h-48'
      default:
        return 'w-32 h-24'
    }
  } else {
    return 'aspect-square'
  }
})

const previewUrl = computed(() => {
  const itemValue = item.value

  if ('list' === view.value) {
    switch (scale.value) {
      case "1":
        return itemValue.previewUrl48
      case "2":
        return itemValue.previewUrl64
      case "4":
        return itemValue.previewUrl128
      case "5":
        return itemValue.previewUrl192
      default:
        return itemValue.previewUrl256
    }
  } else {
    switch (scale.value) {
      case "1":
        return itemValue.previewUrl110
      case "2":
        return itemValue.previewUrl140
      case "4":
        return itemValue.previewUrl260
      case "5":
        return itemValue.previewUrl410
      default:
        return itemValue.previewUrl190
    }
  }
})

const mediaType = computed(() => item.value?.mediaType)
const mediaTypeIcon = computed(() => {
  if (mediaType.value?.startsWith('image/')) {
    return mdiFileImageOutline
  } else if (mediaType.value?.startsWith("video/")) {
    return mdiFileVideoOutline
  } else {
    return mdiFileOutline
  }
})

const figcaptionLayoutClasses = computed(() => {
  if ('list' === view.value) {
    return ''
  } else {
    return 'text-center'
  }
})

const isMac = navigator.platform.toLowerCase().startsWith("mac")

function clickContainer(event: MouseEvent) {
  if (event.getModifierState(isMac ? 'Meta' : 'Control')) {
    emit('select', Selection.MULTI)
  } else if (event.getModifierState('Shift')) {
    emit('select', Selection.RANGE)
  } else {
    emit('select', Selection.SIGNLE)
  }
}

function clickInput(event: MouseEvent) {
  emit('select', Selection.MULTI)
}
</script>

<template>
  <div
    class="w-full flex items-stretch relative p-4 group cursor-pointer rounded-xl"
    :class="[
      isSelected ? 'bg-amber-100' : 'hover:bg-amber-50'
    ]"
    :style="{ order: order }"
    @click="clickContainer"
  >
    <div class="relative w-full flex-1 flex gap-2 justify-center">
      <input
        type="checkbox"
        class="checkbox"
        :class="isListView ? '' : 'absolute top-1 left-1'"
        :checked="isSelected"
        @click.stop="clickInput"
      >

      <figure class="w-full" :class="figureLayoutClasses">
        <img
          v-if="previewUrl"
          :src="previewUrl"
          :alt="item.filename"
          class="w-full bg-white object-contain"
          :class="imageLayoutClasses"
        >
        <div
          v-else
          class="flex items-center justify-center aspect-square"
          :class="imageLayoutClasses"
        >
          <svg-icon
            type="mdi"
            :path="mediaTypeIcon"
            class="w-16 h-16"
          />
        </div>

        <figcaption
          class="truncate p-2"
          :class="figcaptionLayoutClasses"
        >
          <span>{{ item.filename }}</span>
          <span class="text-xs text-gray-600 ml-4">{{ toHumanReadableSize(item.length) }}</span>
        </figcaption>

        <div class="flex flex-wrap items-center gap-1">
          <Tag
            v-for="tag of item.tags"
            :key="tag.uuid"
            :tag="tag"
            dense
            icon
          />
        </div>
      </figure>
    </div>
  </div>
</template>
