export type GraphqlDate = string
export type GraphqlDateTime = string
export type GraphqlTime = string
export type GraphqlZonedDateTime = string
import { DateTime, Settings } from "luxon"

Settings.defaultZone = "Europe/Berlin"

export function loadNavigatorLocale(): Promise<string> {
    const navigatorLanguage = navigator.language.toLocaleLowerCase()
    Settings.defaultLocale = navigatorLanguage
    return Promise.resolve(navigatorLanguage)
}

export function now(): DateTime {
    return DateTime.now()
}

const ISO_DATE_TIME_WITH_ZONE_PATTERN = "yyyy-MM-dd'T'HH:mm:ssZZ[z]"
const ISO_DATE_TIME_WITH_MILLIS_AND_ZONE_PATTERN =
    "yyyy-MM-dd'T'HH:mm:ss.uZZ[z]"

function parseIso(input: string): DateTime | undefined {
    const dateTime = DateTime.fromISO(input)
    return dateTime.isValid ? dateTime : undefined
}

function parseFormat(input: string, format: string): DateTime | undefined {
    const dateTime = DateTime.fromFormat(input, format)
    return dateTime.isValid ? dateTime : undefined
}

export function parseIsoDate(isoDate: string): DateTime | undefined {
    return parseIso(isoDate)
}

export function parseIsoTime(isoTime: string): DateTime | undefined {
    return parseIso(isoTime)
}

export function parseIsoDateTime(isoDate: string): DateTime | undefined {
    return (
        parseFormat(isoDate, ISO_DATE_TIME_WITH_MILLIS_AND_ZONE_PATTERN) ||
        parseFormat(isoDate, ISO_DATE_TIME_WITH_ZONE_PATTERN) ||
        parseIso(isoDate)
    )
}

export function parseLocaleDate(localeDate: string): DateTime | undefined {
    return parseFormat(localeDate, "D")
}

export function parseLocaleTime(localeTime: string): DateTime | undefined {
    return parseFormat(localeTime, "tt") || parseFormat(localeTime, "t")
}

export function formatToLocaleDate(date: DateTime, locale?: string): string {
    return date.toFormat("D", { locale })
}

export function formatToLocaleTime(date: DateTime, locale?: string): string {
    return date.toFormat("t", { locale })
}

export function formatToLocaleDateTime(
    date: DateTime,
    locale?: string
): string {
    return date.toFormat("f", { locale })
}

export function formatIsoDateStringToLocaleDate(
    isoDate: string
): string | undefined {
    const date = parseIsoDate(isoDate)
    return date ? formatToLocaleDate(date) : undefined
}

export function formatIsoTimeStringToLocaleTime(
    isoDate: string
): string | undefined {
    const date = parseIsoTime(isoDate)
    return date ? formatToLocaleTime(date) : undefined
}

export function formatIsoDateTimeStringToLocaleDateTime(
    isoDate: string
): string | undefined {
    const date = parseIsoDateTime(isoDate)
    return date ? formatToLocaleDateTime(date) : undefined
}

export function formatToIsoDate(date: DateTime): string {
    return date.toISODate()
}

export function formatToIsoTime(date: DateTime): string {
    return date.toISOTime({ includeOffset: false })
}

export function formatToIsoDateTime(date: DateTime): string {
    return date.toISO()
}
