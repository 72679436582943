<script setup lang="ts">
import { UploadPhase, useUploads } from "./Uploads"
import { Subscription } from "rxjs"
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import { ArrowUpTrayIcon, CloudArrowUpIcon, FolderOpenIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { UploadViewItem } from "./upload-view"
import UploadItem from "./UploadItem.vue"
import { notifyError } from "../notifications"
import { debouncedRef } from "../debounced-ref"
import {UnwrapRef} from "@vue/reactivity"

const items = ref<UploadViewItem[]>([
  // {
  //   upload: {
  //     name: "Preparing.mp4",
  //     size: 9671274582,
  //     state: new Subject(),
  //   },
  //   // preview: "https://picsum.photos/id/8/854/480",
  //   state: {
  //     phase: UploadPhase.PREPARING,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Creating Upload.jpg",
  //     size: 190456,
  //     state: new Subject(),
  //   },
  //   preview: "https://picsum.photos/id/18/320/240",
  //   state: {
  //     phase: UploadPhase.CREATING,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Upload running.gif",
  //     size: 84752,
  //     state: new Subject(),
  //   },
  //   preview: "https://picsum.photos/id/84/240/320",
  //   state: {
  //     phase: UploadPhase.UPLOADING,
  //     progress: 0.44,
  //     bytes: 37290,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Completed.pdf",
  //     size: 7168423,
  //     state: new Subject(),
  //   },
  //   // preview: "https://picsum.photos/id/277/480/480",
  //   state: {
  //     phase: UploadPhase.COMPLETE,
  //     progress: 1,
  //     bytes: 7168423,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Failed.svg",
  //     size: 845247,
  //     state: new Subject(),
  //   },
  //   // preview: "https://picsum.photos/id/277/480/480",
  //   state: {
  //     phase: UploadPhase.FAILED,
  //     progress: 1,
  //     bytes: 7168423,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Preparing.mp4",
  //     size: 9671274582,
  //     state: new Subject(),
  //   },
  //   // preview: "https://picsum.photos/id/8/854/480",
  //   state: {
  //     phase: UploadPhase.PREPARING,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Creating Upload.jpg",
  //     size: 190456,
  //     state: new Subject(),
  //   },
  //   preview: "https://picsum.photos/id/18/320/240",
  //   state: {
  //     phase: UploadPhase.CREATING,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Upload running.gif",
  //     size: 84752,
  //     state: new Subject(),
  //   },
  //   preview: "https://picsum.photos/id/84/240/320",
  //   state: {
  //     phase: UploadPhase.UPLOADING,
  //     progress: 0.44,
  //     bytes: 37290,
  //   },
  //   stateSubscription: new Subscription(),
  // },
  // {
  //   upload: {
  //     name: "Completed.pdf",
  //     size: 7168423,
  //     state: new Subject(),
  //   },
  //   // preview: "https://picsum.photos/id/277/480/480",
  //   state: {
  //     phase: UploadPhase.COMPLETE,
  //     progress: 1,
  //     bytes: 7168423,
  //   },
  //   stateSubscription: new Subscription(),
  // },
])

const uploads = useUploads()

let uploadsSubscription: Subscription

onMounted(() => {
  uploadsSubscription = uploads.subscribe(upload => {
    const item = reactive<UploadViewItem>({
      upload: upload,
      state: { phase: UploadPhase.CREATING },
      stateSubscription: Subscription.EMPTY
    })

    item.stateSubscription = upload.state.subscribe(state => {
      item.state = state
    })

    if (upload.file) {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        item.preview = fileReader.result as string
      }
      fileReader.readAsDataURL(upload.file)
    }

    items.value.unshift(item)
  })
})
onUnmounted(() => {
  uploadsSubscription?.unsubscribe()

  items.value.forEach(item => item.stateSubscription.unsubscribe())
})

function removeItem(item: UploadViewItem) {
  const itemsValue = items.value
  const index = itemsValue.indexOf(item)

  if (-1 !== index) {
    const removedItems = itemsValue.splice(index, 1)
    removedItems.find(item => item.stateSubscription.unsubscribe())
  }
}

const popupVisible = ref(false)

function togglePopup() {
  popupVisible.value = !popupVisible.value
}

watch(popupVisible, popupVisibleValue => {
  document.body.style.overflow = popupVisibleValue ? "hidden" : ""
})

onUnmounted(() => {
  document.body.style.overflow = ""
})

const totalProgress = computed(() => {
  const itemsValue: UnwrapRef<UploadViewItem[]> = items.value

  if (itemsValue.length) {
    const uploadingItems = itemsValue.filter(item => UploadPhase.UPLOADING === item.state.phase)
    return uploadingItems.map(item => item.state.progress ?? 0)
      .reduce((a, b) => a + b, 0) / uploadingItems.length
  } else {
    return 0
  }
})

const fileSelectInput = ref<HTMLInputElement>()
const isDragging = debouncedRef(false, newValue => newValue ? 0 : 100)
const isHoverDragArea = debouncedRef(false, newValue => newValue ? 0 : 100)

function selectFileToUpload() {
  const fileSelectInputValue = fileSelectInput.value
  const files = fileSelectInputValue?.files
  uploadFiles(files)
}

function uploadDroppedFiles(event: DragEvent) {
  const files = event.dataTransfer?.files
  uploadFiles(files)

  isDragging.value = false
  isHoverDragArea.value = false
}

function uploadFiles(files: FileList | null | undefined) {
  if (files && files.length) {
    Array.from(files)
      .forEach(file => {
        uploads.upload(file)
      })
  } else {
    notifyError("Keine Dateien zum Upload ausgewählt")
  }
}
</script>

<template>
  <div
    class="relative w-8 h-8 flex items-center justify-center rounded-full bg-white cursor-pointer"
    @click="togglePopup"
  >
    <ArrowUpTrayIcon
      class="w-3 h-3 text-gray-900"
    />
    <div
      v-if="totalProgress"
      class="absolute radial-progress pointer-events-none"
      :class="[totalProgress < 1 ? 'text-cyan-600' : 'text-green-600']"
      :style="{ '--value': totalProgress * 100 }"
      style="--size: 2rem; --thickness: 2px"
    />
  </div>

  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
      leave-active-class="transition ease-in duration-750"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div
        v-if="popupVisible"
        class="fixed top-0 right-0 bottom-0 left-0 bg-gray-900 bg-opacity-30 backdrop-blur left-0 z-20"
        @dragenter.stop.prevent="isDragging = true"
        @dragleave="isDragging = false"
        @dragend="isDragging = false"
        @dragover.stop.prevent="isDragging = true"
        @drop="isDragging = false; isHoverDragArea = false"
      />
    </transition>

    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-750"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="popupVisible"
        class="fixed top-0 right-0 bottom-0 left-0 p-20 z-30 pointer-events-none"
        @dragenter.stop.prevent="isDragging = true"
        @dragleave="isDragging = false"
        @dragend="isDragging = false"
        @dragover.stop.prevent="isDragging = true"
        @drop="isDragging = false; isHoverDragArea = false"
      >
        <div class="md:pl-64 md:mr-24">
          <div
            class="relative max-w-4xl mx-auto pointer-events-auto"
            @click.stop
          >
            <div
              class="max-h-[calc(100vh_-_10rem)] flex flex-col md:px-8 xl:px-0 bg-white shadow-lg md:rounded-lg divide-y divide-gray-200 overflow-auto"
            >
              <div
                v-if="!items.length || isDragging"
                class="p-10"
                @dragenter.passive="isHoverDragArea = true"
                @dragleave.passive="isHoverDragArea = false"
                @dragend.passive="isHoverDragArea = false"
                @dragover.passive="isHoverDragArea = true"
                @drop.stop.prevent="uploadDroppedFiles"
              >
                <div
                  class="flex flex-col items-center justify-center border-4 border-dashed rounded-xl p-20"
                  :class="isHoverDragArea ? 'border-amber-500' : 'border-gray-300'"
                >
                  <CloudArrowUpIcon
                    class="w-20 h-20"
                    :class="isHoverDragArea ? 'text-amber-500' : 'text-gray-300'"
                  />
                  <div class="mt-5">
                    Datei hier ablegen
                  </div>
                </div>
              </div>
              <template v-else>
                <UploadItem
                  v-for="(item, index) of items"
                  :key="index"
                  :item="item"
                  @remove="removeItem"
                />
              </template>
            </div>

            <div class="absolute left-full top-0 pl-6">
              <div class="w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer"
                   @click="togglePopup">
                <XMarkIcon class="w-8 h-8" />
              </div>
              <label class="mt-4 w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer">
                <FolderOpenIcon class="w-8 h-8" />
                <input
                  ref="fileSelectInput"
                  type="file"
                  multiple
                  accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,.pdf,video/mp4"
                  class="!absolute w-0 h-0 overflow-hidden clip[rect(1px,1px,1px,1px)]"
                  @change="selectFileToUpload"
                >
              </label>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
