/// <reference types="vite/client" />
import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router"
import zitadelAuth from "./zitadelAuth";
import {Role} from "./graphql/types";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: () => import("./dashboard/Dashboard.vue"),
  },
  {
    path: '/articles',
    name: 'article-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_ARTICLES)
      ? import('./articles/ArticleList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/articles/:id',
    name: 'article',
    component: () => zitadelAuth.hasRole(Role.MANAGE_ARTICLES)
      ? import('./articles/Article.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/rating-categories',
    name: 'rating-category-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_RATING_CATEGORIES)
      ? import('./rating-categories/RatingCategoryList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/rating-categories/:id',
    name: 'rating-category',
    component: () => zitadelAuth.hasRole(Role.MANAGE_RATING_CATEGORIES)
      ? import('./rating-categories/RatingCategory.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/games',
    name: 'game-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAMES)
      ? import('./games/GameList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/games/:id',
    name: 'game',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAMES)
      ? import('./games/Game.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/game-series',
    name: 'game-series-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAME_SERIES)
      ? import('./gameseries/GameSeriesList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/game-series/:id',
    name: 'game-series',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAME_SERIES)
      ? import('./gameseries/GameSeries.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/genres',
    name: 'genre-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GENRES)
      ? import('./genre/GenreList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/genres/:id',
    name: 'genre',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GENRES)
      ? import('./genre/Genre.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/game-consoles',
    name: 'game-console-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAME_CONSOLES)
      ? import('./gameconsole/GameConsoleList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/game-consoles/:id',
    name: 'game-console',
    component: () => zitadelAuth.hasRole(Role.MANAGE_GAME_CONSOLES)
      ? import('./gameconsole/GameConsole.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/companies',
    name: 'company-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_COMPANIES)
      ? import('./companies/CompanyList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/companies/:id',
    name: 'company',
    component: () => zitadelAuth.hasRole(Role.MANAGE_COMPANIES)
      ? import('./companies/Company.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/media',
    name: 'media-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_MEDIA)
      ? import('./media/MediaList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/media/:id',
    name: 'media',
    component: () => zitadelAuth.hasRole(Role.MANAGE_MEDIA)
      ? import('./media/Media.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/tags',
    name: 'tag-list',
    component: () => zitadelAuth.hasRole(Role.MANAGE_TAG)
      ? import('./tag/TagList.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: '/tags/:id',
    name: 'tag',
    component: () => zitadelAuth.hasRole(Role.MANAGE_TAG)
      ? import('./tag/Tag.vue')
      : import('./NoAccess.vue'),
  },
  {
    path: "/authors",
    name: "author-list",
    component: () => zitadelAuth.hasRole(Role.MANAGE_AUTHORS)
      ? import("./author/AuthorList.vue")
      : import('./NoAccess.vue'),
  },
  {
    path: "/authors/:id",
    name: "author",
    component: () => zitadelAuth.hasRole(Role.MANAGE_AUTHORS)
      ? import("./author/Author.vue")
      : import('./NoAccess.vue'),
  },
  {
    path: "/system",
    name: "system",
    component: () => zitadelAuth.hasRole(Role.SYSTEM_ADMINISTRATION)
      ? import("./system/System.vue")
      : import('./NoAccess.vue'),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import('./NoAccess.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {top: 0}
  },
})

zitadelAuth.oidcAuth.useRouter(router)

export default router
