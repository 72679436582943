import {BehaviorSubject, Subject} from "rxjs"
import {createSHA512} from "hash-wasm"
import {
  GetMediaDocument,
  GetMediaQuery,
  GetMediaQueryVariables,
  Media,
  MediaDetailsFragment,
  PrepareUploadDocument,
  PrepareUploadInput,
  PrepareUploadMutation,
  PrepareUploadMutationVariables,
  PrepareUploadPartInput,
  Resizing,
  UploadUuidAndPartNumberWithUploadUrlFragment,
} from "../graphql/types"
import {IHasher} from "hash-wasm/dist/lib/WASMInterface"
import {Upload, UploadPhase, UploadState} from "./Uploads"
import {Logger} from "../logger"
import {ApolloClient, NormalizedCacheObject} from "@apollo/client/core"
import {notifyErrors} from "../notifications"
import {Ref} from "vue"
import axios from "axios"

interface UploadPartResponse {
  ok: boolean
  remainingSize: number
  incompleteParts?: [number]
  uploadCompleted: boolean
  media?: string
  etag?: string
  error?: string
}

const PART_SIZE = 1024 * 1024 // 1 MB
const SINGLE_PART_THRESHOLD = 2 * PART_SIZE

export class UploadImpl implements Upload {
  get name(): string {
    return this.file.name
  }

  public size = 0
  readonly state: Subject<UploadState>
  private processedBytes = 0

  constructor(
    public file: File,
    private token: Ref<string | null>,
    private graphql: ApolloClient<NormalizedCacheObject>,
    private log: Logger
  ) {
    log.debug(
      `Starting upload ${file.name} (size: ${file.size}, type: ${file.type})`
    )

    this.size = file.size
    this.state = new BehaviorSubject<UploadState>({
      phase: UploadPhase.PREPARING,
    })

    this.processedBytes = 0
    this.state.next({
      phase: UploadPhase.PREPARING,
      progress: 0,
      bytes: 0
    })

    createSHA512()
      .then(async (sha512) => {
        sha512.init()
        const parts: PrepareUploadPartInput[] =
          file.size <= SINGLE_PART_THRESHOLD
            ? await this.createPrepareUploadPartInput(0, file, sha512).then(
                (input) => [input]
              )
            : await this.createPrepareUploadPartInputs(sha512)
        const sha512sum = sha512.digest("hex")

        return {
          filename: this.file.name,
          mediaType: this.file.type,
          sha512sum,
          parts,
        } as PrepareUploadInput
      })
      .then((prepareUploadInput) => {
        this.log.debug(
          `[${this.file.name}] Creating upload with ${prepareUploadInput.parts.length} parts (sha512sum: ${prepareUploadInput.sha512sum})`
        )

        this.state.next({
          phase: UploadPhase.CREATING,
          progress: -1,
        })

        const variables: PrepareUploadMutationVariables = {
          input: prepareUploadInput,
        }

        return this.graphql
          .mutate<PrepareUploadMutation, PrepareUploadMutationVariables>({
            mutation: PrepareUploadDocument,
            variables,
          })
          .then((query) => {
            if (query.errors?.length) {
              notifyErrors(query.errors)
              this.state.next({
                phase: UploadPhase.FAILED,
                error: query.errors[0].message
              })
            }

            return query.data?.prepareUpload
          })
      })
      .then((upload) => {
        if (upload) {
          return this.uploadParts(upload)
        }
      })
      .then((media) => {
        if (media) {
          this.log.debug(`[${this.file.name}] Upload completed`)

          this.state.next({
            phase: UploadPhase.COMPLETE,
            progress: 1,
            media,
          })
        }
      })
      .catch((error) => {
        this.log.error(`[${this.file.name}] Uploading failed: ${error}`)

        this.state.next({
          phase: UploadPhase.FAILED,
          error,
        })
      })
  }

  private async createPrepareUploadPartInputs(
    fileSha512: IHasher
  ): Promise<Array<PrepareUploadPartInput>> {
    this.log.debug(`[${this.file.name}] Calculating upload parts`)

    const parts: PrepareUploadPartInput[] = []

    const size = this.file.size
    let remainingSize = size

    while (remainingSize > 0) {
      const start = size - remainingSize
      const end = Math.min(this.file.size, start + PART_SIZE)
      remainingSize -= PART_SIZE

      const slice = this.file.slice(start, end, "application/octet-stream")
      const part = await this.createPrepareUploadPartInput(
        parts.length,
        slice,
        fileSha512
      )

      parts.push(part)
    }

    return parts
  }

  private async createPrepareUploadPartInput(
    number: number,
    blob: Blob,
    fileSha512: IHasher
  ): Promise<PrepareUploadPartInput> {
    this.log.debug(`[${this.file.name}] Calculating upload ${number}' part`)

    const sha512 = await createSHA512()
    const stream: ReadableStream = blob.stream()
    const reader: ReadableStreamDefaultReader<Uint8Array> = stream.getReader()
    sha512.init()

    const push = async (): Promise<void> => {
      return reader.read().then(({ done, value }) => {
        if (value) {
          this.processedBytes += value.byteLength
          this.state.next({
            phase: UploadPhase.PREPARING,
            progress: this.processedBytes / this.file.size,
            bytes: this.processedBytes
          })

          sha512.update(value)
          fileSha512.update(value)
          return push()
        } else if (!done) {
          throw new Error("What happen here?")
        }
      })
    }

    await push()
    const sha512sum = sha512.digest("hex")

    return {
      number,
      length: blob.size,
      sha512sum,
    }
  }

  private async uploadParts(
    upload: UploadUuidAndPartNumberWithUploadUrlFragment
  ): Promise<MediaDetailsFragment & Pick<Media, "previewUrl">> {
    this.log.debug(`[${this.file.name}] Uploading parts`)

    this.processedBytes = 0
    this.state.next({
      phase: UploadPhase.UPLOADING,
      progress: 0,
      bytes: 0
    })

    const responses: Array<UploadPartResponse> = []

    if (upload.parts.length > 1) {
      for (const part of upload.parts) {
        const start = part.number * PART_SIZE
        const end = Math.min(this.file.size, start + PART_SIZE)
        const blob = this.file.slice(start, end, "application/octet-stream")

        responses.push(await this.uploadPart(part.number, part.uploadUrl, blob))
      }
    } else {
      responses.push(
        await this.uploadPart(0, upload.parts[0].uploadUrl, this.file)
      )
    }

    if (!responses.length) {
      throw new Error("No responses")
    }

    const completedResponse = responses.find(
      (response) => response.uploadCompleted
    )

    if (!completedResponse) {
      throw new Error("Incomplete")
    }

    const mediaId = completedResponse.media

    if (!mediaId) {
      throw new Error("Media ID missing")
    }

    const variables: GetMediaQueryVariables = {
      id: mediaId,
      width: 40,
      height: 40,
      resizing: Resizing.FILL,
    }

    return this.graphql
      .query<GetMediaQuery, GetMediaQueryVariables>({
        query: GetMediaDocument,
        variables,
      })
      .then((response) => {
        if (response.data?.media) {
          return response.data.media
        } else {
          throw new Error(`Media ${mediaId} not found`)
        }
      })
  }

  private async uploadPart(
    number: number,
    uploadUrl: string,
    blob: Blob
  ): Promise<UploadPartResponse> {
    this.log.debug(`[${this.file.name}] Uploading part ${number}`)

    const body = blob

    // const body = blob.stream().pipeThrough(new TransformStream<Uint8Array, Uint8Array>({
    //     transform: async (chunk, controller) => {
    //         this.processedBytes += chunk.byteLength;
    //         this.state.next({
    //             phase: UploadPhase.UPLOADING,
    //             progress: this.processedBytes / this.file.size
    //         });
    //
    //         controller.enqueue(chunk);
    //     }
    // }));

    // return fetch(uploadUrl, {
    //   method: 'POST',
    //   body,
    //   headers: {
    //     "authorization": `Bearer ${this.token}`
    //   }
    // })
    //   .then(async response => {
    //     const data = (await response.json()) as UploadPartResponse
    //
    //     if (response.ok) {
    //       this.processedBytes += blob.size
    //       this.state.next({
    //         phase: UploadPhase.UPLOADING,
    //         progress: this.processedBytes / this.file.size
    //       })
    //       return data
    //     } else if (data?.error) {
    //       throw new Error(`Uploading part ${number} failed: ${data.error}`)
    //     } else {
    //       throw new Error(`Uploading part ${number} failed`)
    //     }
    //   })

    return axios
      .post(uploadUrl, body, {
        headers: {
          authorization: `Bearer ${this.token.value}`,
          'content-type': body.type,
        },
        onDownloadProgress: (event) => {
          this.processedBytes = event.bytes
          this.state.next({
            phase: UploadPhase.UPLOADING,
            progress: this.processedBytes / this.file.size,
            bytes: this.processedBytes
          })
        },
        onUploadProgress: (event) => {
          this.processedBytes = event.bytes
          this.state.next({
            phase: UploadPhase.UPLOADING,
            progress: this.processedBytes / this.file.size,
            bytes: this.processedBytes
          })
        },
      })
      .then((response) => {
        const data: UploadPartResponse | undefined =
          response.data as UploadPartResponse

        if (200 == response.status) {
          return data
        } else if (data?.error) {
          throw new Error(`Uploading part ${number} failed: ${data.error}`)
        } else {
          throw new Error(
            `Uploading part ${number} failed: ${response.status} ${response.statusText}`
          )
        }
      })
  }
}
