import { inject } from "vue"
import * as log from "loglevel"

export const LoggerKey = Symbol("logger")

export interface Logger {
    trace(...args: unknown[]): void

    debug(...args: unknown[]): void

    info(...args: unknown[]): void

    warn(...args: unknown[]): void

    error(...args: unknown[]): void
}

export function createLogger(): Logger {
    const isProduction = import.meta.env.PROD
    log.setDefaultLevel(isProduction ? "WARN" : "TRACE")
    return log
}

export function useLogger(): Logger {
    return inject(LoggerKey) as Logger
}
