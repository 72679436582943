import { notify } from "notiwind"
import { isRef, Ref, watchEffect } from "vue"
import {ApolloQueryResult} from "@apollo/client"

type Notification = { title?: string; text: string; details?: string } | string
type DescribedRefError =
    | Ref<Error | null | undefined>
    | { error: Ref<Error | null | undefined>; description: string }
type DescribedError =
    | Error
    | null
    | undefined
    | { error: Error | null | undefined; description: string }
    | Notification

export function notifySuccess(notification: Notification, time?: number) {
    if ("string" === typeof notification) {
        notify(
            {
                group: "app",
                kind: "success",
                text: notification,
            },
            time || 4000
        )
    } else {
        notify(
            {
                group: "app",
                kind: "success",
                ...notification,
            },
            time || 4000
        )
    }
}

export function notifyError(notification: DescribedError, time?: number) {
    if (notification) {
        console.error(notification)

        if ("string" === typeof notification) {
            notify(
                {
                    group: "app",
                    kind: "error",
                    text: notification,
                },
                time || 10000
            )
        } else if (notification instanceof Error || "message" in notification) {
            const title = notification.name
            const text = notification.message

            notify(
                {
                    group: "app",
                    kind: "error",
                    title,
                    text,
                },
                time || 10000
            )
        } else if ("error" in notification && "description" in notification) {
            const error = notification.error
            if (error) {
                const title = error.name
                const text = notification.description
                const details = error.message

                notify(
                    {
                        group: "app",
                        kind: "error",
                        title,
                        text,
                        details,
                    },
                    time || 10000
                )
            }
        } else {
            notify(
                {
                    group: "app",
                    kind: "error",
                    ...notification,
                },
                time || 10000
            )
        }
    }
}

export function notifyErrors(
    errors: readonly (DescribedError | undefined)[] | undefined
) {
    if (errors) {
        errors.forEach((error) => {
            notifyError(error)
        })
    }
}

export function watchErrors(...args: DescribedRefError[]) {
    args.forEach((arg) => {
        watchEffect(() => {
            const ref = isRef(arg) ? arg : arg.error
            if (ref.value) {
                const title = ref.value.name
                const text =
                    "description" in arg ? arg.description : ref.value.message
                const details = "description" in arg ? ref.value.message : ""

                notifyError({ title, text, details })
            }
        })
    })
}

export function catchErrors<T>(
    fallbackValue?: T
): (reason: Error) => T | undefined {
    return (reason: Error) => {
        notifyError(reason)
        return fallbackValue
    }
}

export function handleGraphQlErrorsOrReturnData<T>(result: ApolloQueryResult<T>): T {
  if (result.error) {
    notifyError(result.error)
    throw result.error
  } else if (result.errors) {
    notifyErrors(result.errors)
    throw new Error(result.errors.map(e => e.message).join(", "))
  } else {
    return result.data
  }
}
