<script setup lang="ts">
import { UploadPhase } from "./Uploads"
import { toRefs } from "vue"
import { PhotoIcon, VideoCameraIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { toHumanReadableSize } from "../size"
import { UploadViewItem } from "./upload-view"

const props = defineProps<{
  item: UploadViewItem
}>()

const emit = defineEmits<{
  (e: "remove", item: UploadViewItem): void
}>()

const { item } = toRefs(props)

function isVideo(item: UploadViewItem): boolean {
  return item.upload.name.endsWith(".mp4")
    || item.upload.name.endsWith(".mkv")
    || item.upload.name.endsWith(".ogv")
    || item.upload.name.endsWith(".mov")
    || item.upload.name.endsWith(".mkv")
    || item.upload.name.endsWith(".webm")
}

function isFailed(item: UploadViewItem): boolean {
  return UploadPhase.FAILED === item.state.phase
}

function isPreparing(item: UploadViewItem): boolean {
  return UploadPhase.PREPARING === item.state.phase
}

function isCreating(item: UploadViewItem): boolean {
  return UploadPhase.CREATING === item.state.phase
}

function isUploading(item: UploadViewItem): boolean {
  return UploadPhase.UPLOADING === item.state.phase
}

function isCompleted(item: UploadViewItem): boolean {
  return UploadPhase.COMPLETE === item.state.phase
}

function toPercentage(progress: number): string {
  return (progress * 100).toFixed(1)
    .replace(".0", "") + " %"
}
</script>

<template>
  <div
    class="relative flex gap-4 p-8"
  >
    <div class="flex items-center justify-center w-16 h-16">
      <img
        v-if="item.preview"
        :src="item.preview"
        alt="Upload Vorschau"
        class="w-16 h-16 object-contain"
      >
      <VideoCameraIcon
        v-else-if="isVideo(item)"
        class="w-12 h-12"
      />
      <PhotoIcon
        v-else
        class="w-12 h-12"
      />
    </div>

    <div class="flex-grow">
      <div>
        <span class="font-mono inline-block text-amber-900">
          {{ item.upload.name }}
        </span>
      </div>
      <div class="flex gap-2 mt-1">
        <span v-if="isPreparing(item)">Datei wird analysiert&hellip;</span>
        <span v-if="isCreating(item)">Upload wird vorbereitet&hellip;</span>
        <span v-if="item.state.progress">{{ toPercentage(item.state.progress) }}</span>
        <span v-if="item.state.progress && item.state.bytes">/</span>
        <span v-if="item.state.bytes">{{ toHumanReadableSize(item.state.bytes) }}</span>
        <span v-if="(item.state.progress || item.state.bytes) && item.upload.size">von</span>
        <span v-if="item.upload.size">{{ toHumanReadableSize(item.upload.size) }}</span>
      </div>
      <div class="mt-1 h-4 p-1 bg-gray-100 rounded-lg shadow">
        <div
          v-if="isFailed(item)"
          class="h-2 rounded-lg bg-red-400"
        />
        <div
          v-else-if="isCompleted(item)"
          class="h-2 rounded-lg bg-green-400"
        />
        <div
          v-else-if="item.state.progress"
          class="h-2 rounded-lg bg-cyan-400 bg-stripe-gradient bg-[size:24px] animate-bg-move"
          :style="{ width: (item.state.progress * 100) + '%' }"
        />
        <div
          v-else-if="isPreparing(item) || isCreating(item)"
          class="h-2 rounded-lg bg-stripe-gradient bg-[size:24px] animate-bg-move bg-yellow-400 animate-pulse"
        />
      </div>
    </div>

    <div v-if="isCompleted(item) || isFailed(item)" class="absolute right-8 top-8">
      <XMarkIcon
        class="w-4 h-4 text-gray-300 hover:text-gray-700 cursor-pointer"
        @click="emit('remove', item)"
      />
    </div>
  </div>
</template>
