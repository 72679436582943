import { computed, Ref, ref, watch } from "vue"
import {
  GetTagsQuery,
  GetTagsQueryVariables, ReactiveFunction,
  TagListItemFragment,
  useGetTagsQuery,
} from "../graphql/types"
import { UseQueryReturn } from "@vue/apollo-composable/dist/useQuery"
import { UseQueryOptions } from "@vue/apollo-composable"

export type AllTagsReturn = UseQueryReturn<
  GetTagsQuery,
  GetTagsQueryVariables
> & {
  allTags: Ref<Array<Readonly<TagListItemFragment>>>
}

export function useGetAllTags(
  options?: UseQueryOptions<GetTagsQuery, GetTagsQueryVariables> | Ref<UseQueryOptions<GetTagsQuery, GetTagsQueryVariables>> | ReactiveFunction<UseQueryOptions<GetTagsQuery, GetTagsQueryVariables>>
): AllTagsReturn {
  const limit = ref(1000)
  const variables = computed<GetTagsQueryVariables>(() => ({
    page: 0,
    limit: limit.value,
  }))
  const r = useGetTagsQuery(variables, options)
  const totalPages = computed(() => r.result.value?.allTags?.totalPages || 0)
  const allTags = computed(() => r.result.value?.allTags?.content || [])

  watch(totalPages, (totalPagesValue) => {
    if (totalPagesValue > 1) {
      limit.value = limit.value + 1000
    }
  })

  return { ...r, allTags }
}
