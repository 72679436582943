export function toHumanReadableSize(bytes: number): string {
  if (bytes < 1024) {
    return `${bytes} B`
  }

  bytes /= 1024

  if (bytes < 1024) {
    return `${bytes.toFixed(1)} kB`
  }

  bytes /= 1024

  if (bytes < 1024) {
    return `${bytes.toFixed(1)} MB`
  }

  bytes /= 1024
  return `${bytes.toFixed(1)} GB`
}