<script setup lang="ts">
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/24/solid'
import {computed, toRefs} from "vue";
import {RouteLocationRaw, useRoute} from "vue-router";

const $props = defineProps<{
  page: number
  totalPages: number
  updateRoute?: boolean
}>()

const {page, totalPages, updateRoute} = toRefs($props)

const emit = defineEmits<{
  (e: 'update:page', page: number): void
}>()

const $route = useRoute()

function buildRouteLocation(newPage: number): RouteLocationRaw {
  if (updateRoute?.value) {
    return {
      query: {
        ...$route.query,
        page: newPage
      }
    }
  } else {
    return {}
  }
}

const previousPage = computed<number | undefined>(() => {
  if (page.value > 1) {
    return page.value - 1
  } else {
    return undefined
  }
})

const previousLink = computed<RouteLocationRaw|undefined>(() => {
  const previousPageValue = previousPage.value
  if (undefined !== previousPageValue) {
    return buildRouteLocation(previousPageValue)
  } else {
    return undefined
  }
})

const nextPage = computed<number | undefined>(() => {
  if (page.value < totalPages.value) {
    return page.value + 1
  } else {
    return undefined
  }
})

const nextLink = computed<RouteLocationRaw|undefined>(() => {
  const nextPageValue = nextPage.value
  if (undefined !== nextPageValue) {
    return buildRouteLocation(nextPageValue)
  } else {
    return undefined
  }
})

type HellipLink = { hellip: true }
type RouteLink = {
  label: string,
  to: RouteLocationRaw,
  page: number
}
type Link = HellipLink | RouteLink

const pageLinks = computed<Link[]>(() => {
  const firstPage = Math.max(1, page.value - 4)
  const lastPage = Math.min(totalPages.value, page.value + 4)

  const result: Array<Link> = []

  if (firstPage > 1) {
    result.push({
      label: '1',
      to: buildRouteLocation(1),
      page: 1
    })
  }

  if (firstPage > 2) {
    result.push({ hellip: true })
  }

  for (let p = firstPage; p <= lastPage; p ++) {
    result.push({
      label: p.toString(),
      to: buildRouteLocation(p),
      page: p
    })
  }

  if (lastPage < (totalPages.value - 1)) {
    result.push({ hellip: true })
  }

  if (lastPage < totalPages.value) {
    result.push({
      label: totalPages.value.toString(),
      to: buildRouteLocation(page.value + 1),
      page: page.value + 1
    })
  }

  return result
})
</script>

<template>
  <nav class="px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <router-link
        :to="previousLink || '#'"
        :class="[
          previousLink ? 'text-gray-500 hover:text-gray-700 hover:border-gray-300' : 'text-gray-200 pointer-events-none',
          'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium'
        ]"
        @click="updateRoute ? true : emit('update:page', previousPage)"
      >
        <ArrowLongLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        Previous
      </router-link>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <template
        v-for="link in pageLinks"
        :key="link"
      >
        <span v-if="link.hellip" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"> &hellip; </span>
        <router-link
          v-else
          v-slot="{ href, navigate, isExactActive }"
          :to="link.to"
          custom
        >
          <a
            :href="href"
            :class="[
              isExactActive && page === link.page
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
            ]"
            :aria-current="isExactActive && page === link.page ? 'page' : undefined"
            @click.stop.prevent="updateRoute ? navigate() : emit('update:page', link.page)"
          >
            {{ link.label }}
          </a>
        </router-link>
      </template>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <router-link
        :to="nextLink || '#'"
        :class="[
          nextLink ? 'text-gray-500 hover:text-gray-700 hover:border-gray-300' : 'text-gray-200 pointer-events-none',
          'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium'
        ]"
        @click="updateRoute ? true : emit('update:page', nextPage)"
      >
        Next
        <ArrowLongRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      </router-link>
    </div>
  </nav>
</template>
