import { customRef, ref, Ref } from "vue"
import { UnwrapRef } from "@vue/reactivity"

export interface DelayFunction<T> {
  (value: T): number
}

export function debouncedRef<T>(
  initialValue: T,
  delay: number | DelayFunction<UnwrapRef<T>>,
): Ref<UnwrapRef<T>> {
  const state = ref<T>(initialValue)
  let timeout: any

  return customRef((track, trigger) => ({
    get() {
      track()
      return state.value
    },
    set(value) {
      const delayMillis: number = 'function' === typeof delay ? delay(value) : delay
      clearTimeout(timeout)

      if (delayMillis > 0) {
        timeout = setTimeout(() => {
          state.value = value
          trigger()
        }, delayMillis)
      } else {
        state.value = value
        trigger()
      }
    }
  }))
}