<script setup lang="ts">
import {
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatParagraph,
} from "@mdi/js"
import {FormatDefinition} from "./format"
import TipTapHelpFormatTable from "./TipTapHelpFormatTable.vue"

const formats: FormatDefinition[] = [
  {
    icon: mdiFormatParagraph,
    format: 'Absatz <code>&lt;p&gt;</code>',
    shortcut: '',
    keys: [['Strg', 'Alt', '0']],
  },
  {
    icon: mdiFormatHeader2,
    format: 'Überschrift 2',
    shortcut: '<code>## Überschrift 2</code>',
    keys: [['Strg', 'Alt', '2']],
  },
  {
    icon: mdiFormatHeader3,
    format: 'Überschrift 3',
    shortcut: '<code>### Überschrift 3</code>',
    keys: [['Strg', 'Alt', '3']],
  },
  {
    format: 'Trennlinie <code>&lt;hr&gt;</code>',
    shortcut: '<code>---</code>',
  },
  {
    format: 'Zitatblock <code>&lt;blockquote&gt;</code>',
    shortcut: '<code>&gt; Zitattext</code>',
    keys: [['Strg', 'Shift', 'B']],
  },
  {
    icon: mdiFormatListBulleted,
    format: 'Liste <code>&lt;ul&gt;</code>',
    shortcut: '<code>* Text</code> oder <code>- Text</code> oder <code>+ Text</code>',
    keys: [['Strg', 'Shift', '8']],
  },
  {
    icon: mdiFormatListNumbered,
    format: 'Nummerierte Liste <code>&lt;ol&gt;</code>',
    shortcut: '<code>1. Text</code>',
    keys: [['Strg', 'Shift', '7']],
  },

]
</script>

<template>
  <TipTapHelpFormatTable :formats="formats"/>
</template>
