<script setup lang="ts">
import {Editor, BubbleMenu} from "@tiptap/vue-3"
import SvgIcon from "@jamescoyle/vue-icon"
import {
  mdiFormatBold,
  mdiFormatClear,
  mdiFormatItalic,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiLink,
  mdiLinkOff,
} from "@mdi/js"

const props = defineProps<{
  editor: Editor
}>()

const emit = defineEmits<{
  (e: 'link'): void
}>()
</script>

<template>
  <BubbleMenu
    class="flex gap-1 items-center bg-gray-700 px-2 py-1 rounded text-white"
    :tippy-options="{ duration: 100, zIndex: 1 }"
    :editor="editor"
    v-if="editor"
  >
    <button
      type="button"
      :class="editor.isActive('bold') ? 'text-teal-300 hover:text-white' : 'text-white hover:text-teal-300'"
      @click="editor.chain().focus().toggleBold().run()">
      <SvgIcon type="mdi" :path="mdiFormatBold"/>
    </button>
    <button
      type="button"
      :class="editor.isActive('italic') ? 'text-teal-300 hover:text-white' : 'text-white hover:text-teal-300'"
      @click="editor.chain().focus().toggleItalic().run()">
      <SvgIcon type="mdi" :path="mdiFormatItalic"/>
    </button>
    <button
      type="button"
      :class="editor.isActive('underline') ? 'text-teal-300 hover:text-white' : 'text-white hover:text-teal-300'"
      @click="editor.chain().focus().toggleUnderline().run()">
      <SvgIcon type="mdi" :path="mdiFormatUnderline"/>
    </button>
    <button
      type="button"
      :class="editor.isActive('strike') ? 'text-teal-300 hover:text-white' : 'text-white hover:text-teal-300'"
      @click="editor.chain().focus().toggleStrike().run()">
      <SvgIcon type="mdi" :path="mdiFormatStrikethrough"/>
    </button>
    <button
      type="button"
      :class="editor.isActive('link') ? 'text-teal-300 hover:text-white' : 'text-white hover:text-teal-300'"
      @click="emit('link')">
      <SvgIcon type="mdi" :path="mdiLink"/>
    </button>
    <button
      type="button"
      class="text-teal-300 hover:text-white"
      v-if="editor.isActive('link')"
      @click="editor.chain().focus().unsetLink().run()">
      <SvgIcon type="mdi" :path="mdiLinkOff"/>
    </button>
    <button
      type="button"
      @click="editor.chain().focus().clearNodes().unsetAllMarks().run()">
      <SvgIcon type="mdi" :path="mdiFormatClear"/>
    </button>
  </BubbleMenu>
</template>
