import {
  ApolloClient,
  concat,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core"
import { setContext } from "@apollo/client/link/context"
import { Ref } from "vue"

export function createApolloClient(token: Ref<string | null>) {
  const isProduction = import.meta.env.PROD

  // HTTP connection to the API
  const httpLink = createHttpLink({
    uri: `${SERVER_BASE_URL}/admin/graphql`,
  })

  const authHeaderLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token.value ? `Bearer ${token.value}` : "",
      },
    }
  })

  // Cache implementation
  const cache = new InMemoryCache()

  // Create the apollo client
  return new ApolloClient({
    link: concat(authHeaderLink, httpLink),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
      mutate: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
    connectToDevTools: !isProduction,
  })
}
