<script setup lang="ts">
import {onBeforeUnmount, ref, watchEffect, isProxy, toRaw} from "vue"
import {Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Mention from '@tiptap/extension-mention'
import Youtube from '@tiptap/extension-youtube'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Emoji from '@tiptap-pro/extension-emoji'
import TipTapEmojiSuggestion from './TipTapEmojiSuggestion'
import {FormKitFrameworkContext} from "@formkit/core"
import TipTapHelp from "./TipTapHelp.vue"
import {provideLinkEditorContext} from "./LinkEditorContext"
import TipTapLinkEditor from "./TipTapLinkEditor.vue"
import TipTapToolbar from "./TipTapToolbar.vue"
import TipTabBubbleMenu from "./TipTabBubbleMenu.vue"
import TipTapFloatingMenu from "./TipTapFloatingMenu.vue"

const props = withDefaults(defineProps<{
  context: FormKitFrameworkContext,
  placeholder?: string
}>(), {
  placeholder: 'Schreib ein paar tolle Zeilen...',
})

const editor: Editor = new Editor({
  extensions: [
    // StarterKit contains:
    // Nodes: Blockquote, BulletList, CodeBlock, Document, HardBreak, Heading, HorizontalRule, ListItem, OrderedList, Paragraph, Text
    // Marks: Bold, Code, Italic, Strike
    // Extensions: Dropcursor, Gapcursor, History
    StarterKit.configure({
      heading: {
        levels: [2, 3],
      },
    }),
    // Nodes
    Image,
    Mention,
    Youtube.configure({
      controls: false,
      nocookie: true,
    }),
    Emoji.configure({
      enableEmoticons: true,
      suggestion: TipTapEmojiSuggestion
    }),
    // Marks
    Link.configure({
      openOnClick: false,
    }),
    Underline,
    // Extensions
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    Highlight,
    Typography,
  ],
  content: "",
  onUpdate: () => {
    // HTML
    // props.context.node.input(editor.getHTML())

    // JSON
    props.context.node.input(editor.getJSON())
  },
})
const linkDialog = ref(false)
const linkHref = ref("")
const linkTarget = ref("")
const linkRel = ref("")
const helpDialog = ref(false)

provideLinkEditorContext({
  href: linkHref,
  target: linkTarget,
  rel: linkRel,
})

function isContentJson(_value: any): boolean {
  if ('object' !== typeof _value) {
    return false
  }

  const keys = Object.keys(_value)
  return keys.includes("type") && keys.includes("content")
}

watchEffect(() => {
  // HTML
  // const isSame = editor.getHTML() === props.context._value

  const _value = isProxy(props.context._value) ? toRaw(props.context._value) : props.context._value

  // JSON
  const isSame = JSON.stringify(editor.getJSON()) === JSON.stringify(_value)

  if (!isSame) {
    if ('string' === typeof _value) {
      try {
        editor.commands.setContent(JSON.parse(_value), false)
      } catch (e) {
        console.warn('Failed to parse tiptap content', e)
        editor.commands.setContent(_value, false)
      }
    } else if (isContentJson(_value)) {
      editor.commands.setContent(_value, false)
    } else {
      editor.commands.setContent("", false)
    }
  }
})

watchEffect(() => {
  editor.setEditable(!props.context.attrs.disabled)
})

function showLinkDialog() {
  const link = editor.getAttributes('link')
  linkHref.value = link?.href || ""
  linkTarget.value = link?.target || ""
  linkRel.value = link?.rel || ""

  linkDialog.value = true
}

function onLinkInput(href?: string, target?: string, rel?: string) {
  if (href) {
    editor.chain()
      .focus()
      .setLink({href, target, rel})
      .run()
  } else {
    editor.chain()
      .focus()
      .unsetLink()
      .run()
  }
  linkDialog.value = false
}

function onHelpInput(input: string) {
  editor.chain()
    .insertContent(input)
    .run()
  helpDialog.value = false
}

onBeforeUnmount(() => {
  editor.destroy()
})
</script>

<style lang="scss">
@mixin paragraph-like {
  margin-bottom: .5em;
}

.ProseMirror {
  outline: none;

  h1 {
    font-size: 2em;
    line-height: 2em;
    @include paragraph-like;
  }

  h2 {
    font-size: 1.8em;
    line-height: 1.8em;
    @include paragraph-like;
  }

  h3 {
    font-size: 1.6em;
    line-height: 1.6em;
    @include paragraph-like;
  }

  h4 {
    font-size: 1.4em;
    line-height: 1.4em;
    @include paragraph-like;
  }

  h5 {
    font-size: 1.2em;
    line-height: 1.2em;
    @include paragraph-like;
  }

  h6 {
    font-size: 1em;
    line-height: 1em;
    @include paragraph-like;
  }

  p {
    @include paragraph-like;
  }

  ul {
    padding-left: 1.5em;
    list-style: disc;
    @include paragraph-like;
  }

  ol {
    padding-left: 1.5em;
    list-style: decimal;
    @include paragraph-like;
  }

  a {
    color: darkblue;
    text-decoration: underline;
  }

  iframe {
    border: 8px solid #000;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    display: block;
    outline: 0px solid transparent;
  }

  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  .ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    outline: 6px solid #ece111;
  }
}
</style>

<template>
  <div class="flex-1">
    <TipTapToolbar :editor="editor" @help="helpDialog = !helpDialog"/>
    <TipTabBubbleMenu :editor="editor" @link="showLinkDialog"/>
    <TipTapFloatingMenu :editor="editor"/>

    <EditorContent :editor="editor" :class="context.classes.input"/>

    <TipTapLinkEditor
      :open="linkDialog"
      @close="linkDialog = false"
      @input="onLinkInput"
    />

    <TipTapHelp
      :open="helpDialog"
      @close="helpDialog = false"
      @input="onHelpInput"
    />
  </div>
</template>
