import {inject, provide, Ref} from "vue"

const LinkEditorContextKey = Symbol("LinkEditorContext")

export interface LinkEditorContextData {
  href: Ref<string>
  target: Ref<string>
  rel: Ref<string>
}

export function provideLinkEditorContext(data: LinkEditorContextData) {
  provide(LinkEditorContextKey, data)
}

export function useLinkEditorContext(): LinkEditorContextData {
  const data = inject(LinkEditorContextKey)

  if (!data) {
    throw new Error("LinkEditorContext missing")
  }

  return data as LinkEditorContextData
}
