<script setup lang="ts">
import {
  FindMediasQuery,
  FindMediasQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  MediaListItemFragment,
  TagListItemFragment,
  useFindMediasQuery,
} from "../graphql/types"
import {computed, ref, watch, watchEffect} from "vue"
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"
import {mdiImageFilterHdr, mdiTagTextOutline} from "@mdi/js"
import SvgIcon from "@jamescoyle/vue-icon"
import {UseQueryOptions} from "@vue/apollo-composable"
import {watchErrors} from "../notifications"
import MediaListItem from "../media/MediaListItem.vue"
import {provideMediaListContext, VIEW} from "../media/MediaListContext"
import Pagination from "../components/Pagination.vue"
import {FunnelIcon} from "@heroicons/vue/24/outline"
import {debouncedRef} from "../debounced-ref"
import {useGetAllTags} from "../tag/tags"
import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/vue/20/solid'
import Tag from "../components/Tag.vue"
import MediaPickerPreviewImage from "./MediaPickerPreviewImage.vue"
import Draggable from "vuedraggable"
import {SortableEvent} from "sortablejs"
import {FormKitFrameworkContext} from "@formkit/core"

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const disabled = computed(() => !!props.context.attrs.disabled)

const modelValueArray = computed<Array<MediaListItemFragment>>(() => {
  const value = props.context._value
  if (Array.isArray(value)) {
    return [...value]
  } else if (value) {
    return [value]
  } else {
    return []
  }
})
const multiple = computed(() => {
  return Array.isArray(props.context._value)
})

const selectMedia = ref<Array<MediaListItemFragment>>([])
const selectMediaIds = computed(() => selectMedia.value.map(media => media.uuid))
const firstSelectedMedia = computed<MediaListItemFragment | null | undefined>(() => selectMedia.value[0])

watchEffect(() => {
  selectMedia.value = modelValueArray.value
})

const isOpen = ref(false)
const showSelected = ref(false)

const getAllTagsOptions = computed<UseQueryOptions<GetTagsQuery, GetTagsQueryVariables>>(() => ({
  enabled: isOpen.value,
}))
const {
  allTags,
} = useGetAllTags(getAllTagsOptions)

const query = debouncedRef("", 500)
const selectedTags = ref<Array<TagListItemFragment>>([])

const page = ref(1)
const findMediaVariables = computed<FindMediasQueryVariables>(() => ({
  query: query.value,
  page: page.value - 1,
  limit: 8,
  examples: selectedTags.value ? selectedTags.value.map(t => ({tags: [t.uuid]})) : undefined,
}))
const findMediaOptions = computed<UseQueryOptions<FindMediasQuery, FindMediasQueryVariables>>(() => ({
  enabled: isOpen.value,
}))
const {
  result: findMediaResult,
  loading: findMediaLoading,
  error: findMediaError,
} = useFindMediasQuery(findMediaVariables, findMediaOptions)
const totalPages = computed(() => findMediaResult.value?.findMedia?.totalPages || 0)
const medias = computed(() => findMediaResult.value?.findMedia?.content || [])

watchErrors(findMediaError)

watch([query, selectedTags], () => page.value = 1)

function selected(item: MediaListItemFragment) {
  const selectMediaValue = selectMedia.value
  const index = selectMediaValue.findIndex(media => media.uuid === item.uuid)
  if (-1 === index) {
    if (multiple.value) {
      selectMediaValue.push(item)
    } else {
      selectMedia.value = [item]
    }
  } else {
    selectMediaValue.splice(index, 1)
  }
}

function abort() {
  isOpen.value = false
}

function select() {
  props.context.node.input(multiple.value ? selectMedia.value : firstSelectedMedia.value)
  isOpen.value = false
}

function deselect() {
  props.context.node.input(multiple.value ? [] : null)
  isOpen.value = false
}

function onEndSorting(event: SortableEvent) {
  props.context.node.input(multiple.value ? selectMedia.value : firstSelectedMedia.value)
}

provideMediaListContext({
  isSingleSelect: computed(() => !multiple.value),
  isMultiSelect: multiple,
  selectedItems: selectMedia,
  scale: ref("3"),
  view: ref<VIEW>("grid"),
})
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog :open="isOpen" @close="abort">
      <TransitionChild
        as="template"
        enter-active-class="transition ease-out duration-1000"
        enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transition ease-in duration-750"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div
          class="fixed top-0 right-0 bottom-0 left-0 bg-gray-900 bg-opacity-30 backdrop-blur left-0 z-20"
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter-active-class="transition ease-out duration-1000"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-750"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          class="fixed top-0 right-0 bottom-0 left-0 p-20 z-30 pointer-events-none"
        >
          <div class="md:pl-64 md:mr-24">
            <div class="relative max-w-4xl mx-auto pointer-events-auto" @click.stop>
              <DialogPanel
                class="max-h-[calc(100vh_-_10rem)] p-8 bg-white shadow-lg md:rounded-lg overflow-auto"
                @click.stop
              >
                <div class="relative flex items-center mb-6">
                  <label class="flex-1 flex items-center gap-2 p-2">
                    <FunnelIcon class="w-4 h-4"/>
                    <input
                      class="flex-1 outline-none border-b border-gray-200"
                      v-model="query"
                      :readonly="findMediaLoading"
                    >
                  </label>

                  <Popover v-slot="{ open }" class="relative">
                    <PopoverButton
                      class="
                        inline-flex items-center
                        px-3 py-2
                        text-base font-medium
                        focus:outline-none
                        focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
                      "
                      :class="[
                        selectedTags.length ? 'text-amber-600' : ''
                      ]"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiTagTextOutline"
                        class="w-4 h-4"
                      />
                    </PopoverButton>

                    <transition
                      enter-active-class="transition duration-200 ease-out"
                      enter-from-class="translate-y-1 opacity-0"
                      enter-to-class="translate-y-0 opacity-100"
                      leave-active-class="transition duration-150 ease-in"
                      leave-from-class="translate-y-0 opacity-100"
                      leave-to-class="translate-y-1 opacity-0"
                    >
                      <PopoverPanel
                        class="absolute top-full right-0 z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-3xl"
                      >
                        <div
                          class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                        >
                          <div class="relative flex flex-wrap items-center gap-4 bg-white p-7 lg:grid-cols-2">
                            <Tag
                              v-for="tag of allTags"
                              :key="tag.uuid"
                              :tag="tag"
                              element="label"
                            >
                              <template #icon>
                                <input
                                  v-model="selectedTags"
                                  :value="tag"
                                  type="checkbox"
                                  class="checkbox checkbox-xs"
                                >
                              </template>
                            </Tag>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                </div>

                <div class="grid grid-cols-4 gap-2">
                  <template v-if="medias.length">
                    <MediaListItem
                      v-for="(item, index) of medias"
                      :key="item.uuid"
                      :item="item"
                      :index="index"
                      :is-selected="selectMediaIds.includes(item.uuid)"
                      :class="findMediaLoading ? 'animate-pulse' : ''"
                      @select="selected(item)"
                    />
                  </template>
                  <template v-else-if="findMediaLoading">
                    <div
                      v-for="n of 8"
                      :key="n"
                      class="p-4"
                    >
                      <div
                        class="w-full aspect-square bg-gray-100 rounded-xl animate-pulse"
                      />
                    </div>
                  </template>
                </div>

                <div v-if="multiple && selectMedia.length"
                     class="grid grid-cols-4 gap-2 mt-2 pt-2 border-t border-t-gray-200">
                  <div class="col-span-4 flex items-center gap-1 text-xs p-1 cursor-pointer"
                       @click="showSelected = !showSelected">
                    <ChevronDownIcon class="w-4 h-4" v-if="showSelected"/>
                    <ChevronRightIcon class="w-4 h-4" v-else/>
                    <span>{{ selectMedia.length }} Dateien ausgewählt</span>
                  </div>
                  <template v-if="showSelected">
                    <MediaListItem
                      v-for="(item, index) of selectMedia"
                      :key="item.uuid"
                      :item="item"
                      :index="index"
                      is-selected
                      :class="findMediaLoading ? 'animate-pulse' : ''"
                      @select="selected(item)"
                    />
                  </template>
                </div>

                <Pagination v-model:page="page" :total-pages="totalPages" class="mt-6"/>

                <div class="mt-8 flex items-center justify-between">
                  <div v-if="multiple">{{ selectMedia.length }} Dateien ausgewählt</div>
                  <figure v-else-if="firstSelectedMedia" class="flex items-center gap-2">
                    <img
                      :src="firstSelectedMedia.previewUrl48"
                      :alt="firstSelectedMedia.filename"
                      class="w-12 h-12 object-contain"
                    />
                    <figcaption>
                      <strong>Ausgewählt:</strong><br>{{ firstSelectedMedia.filename }}
                    </figcaption>
                  </figure>
                  <div v-else class="text-gray-400 text-xs">
                    Keine Auswahl
                  </div>
                  <div class="flex items-center gap-3">
                    <button class="btn btn-ghost" @click="abort()">
                      Abbrechen
                    </button>
                    <button class="btn btn-secondary" :class="selectMedia.length ? '' : 'btn-disabled'"
                            @click="deselect()">
                      Abwählen
                    </button>
                    <button class="btn btn-primary" @click="select()">
                      Auswählen
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>

  <div>
    <template v-if="modelValueArray.length">
      <Draggable
        v-model="selectMedia"
        group="items"
        item-key="uuid"
        class="flex gap-4 flex-wrap"
        @end="onEndSorting"
      >
        <template #item="{element}">
          <MediaPickerPreviewImage
            :media="element"
            :disabled="disabled"
            @open="isOpen = true"
          />
        </template>
      </Draggable>
      <!--
          <MediaPickerPreviewImage
            v-for="media of modelValueArray"
            :key="media.uuid"
            :media="media"
            :disabled="disabled"
            @open="isOpen = true"
          />
      -->
    </template>

    <div
      v-else
      class="
        w-48 h-48 p-2 flex items-center justify-center
        shadow-sm hover:shadow-md
        focus:ring-indigo-500 focus:border-indigo-500
        sm:text-sm
        border border-gray-300
        rounded-md
        cursor-pointer
      "
      :class="[
        disabled ? 'bg-gray-100 cursor-not-allowed' : ''
      ]"
      @click="isOpen = true"
    >
      <svg-icon
        type="mdi"
        :path="mdiImageFilterHdr"
        class="w-16 h-16"
      />
    </div>
  </div>

</template>
