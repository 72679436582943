<script setup lang="ts">
import {CheckIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import MainView from "./views/MainView.vue"
import LoginView from "./views/LoginView.vue"
import zitadelAuth from "./zitadelAuth";
import {computed} from "vue";
import {useAllPrivileges} from "./privileges";

const allPrivileges = useAllPrivileges()
const user = computed(() => zitadelAuth.oidcAuth.userProfile)
const hasAnyRole = computed(() => allPrivileges.value.some(zitadelAuth.hasRole))
</script>

<template>
  <MainView v-if="user && hasAnyRole"/>
  <LoginView v-else />

  <NotificationGroup group="app" position="top">
    <div class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Notification
            v-slot="{ notifications, close }"
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
          <div
              class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
              v-for="notification in notifications"
              :key="notification.id"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <XMarkIcon v-if="'error' === notification.kind" class="h-6 w-6 text-red-400"/>
                  <CheckIcon v-else class="h-6 w-6 text-green-400"/>
                </div>

                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p v-if="notification.title" class="text-sm font-medium text-gray-900">
                    {{ notification.title }}
                  </p>
                  <p v-if="notification.text" :class="[notification.title ? 'mt-1' : '', 'text-sm text-gray-500']">
                    {{ notification.text }}
                  </p>
                  <p v-if="notification.details" :class="[(notification.title || notification.text) ? 'mt-1' : '', 'text-sm text-gray-300']">
                    {{ notification.details }}
                  </p>
                </div>

                <div class="ml-4 flex-shrink-0 flex">
                  <button @click="close"
                          class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Schließen</span>
                    <XMarkIcon class="h-5 w-5" aria-hidden="true"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
