<script setup lang="ts">
import {MediaListItemFragment} from "../graphql/types";

defineProps<{
  media: MediaListItemFragment
  disabled: boolean
}>()

const emit = defineEmits<{
  (e: 'open'): void
}>()
</script>
<template>
  <div
    class="
          w-48 h-48 p-2 flex items-center justify-center
          shadow-sm hover:shadow-md
          focus:ring-indigo-500 focus:border-indigo-500
          sm:text-sm
          border border-gray-300
          rounded-md
          cursor-pointer
        "
    :class="[
          disabled ? 'bg-gray-100 cursor-not-allowed' : ''
        ]"
    @click="emit('open')"
  >
    <img
      :src="media.previewUrl256"
      :alt="media.filename"
      :class="{ 'opacity-30': disabled }"
    />
  </div>
</template>
