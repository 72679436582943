import { TagListItemFragment } from "./graphql/types"

const HEX_COLOR_REGEX =
    /^#(?<r>[0-9a-fA-F]{2})(?<g>[0-9a-fA-F]{2})(?<b>[0-9a-fA-F]{2})$/
const SHORT_HEX_COLOR_REGEX =
    /^#(?<r>[0-9a-fA-F])(?<g>[0-9a-fA-F])(?<b>[0-9a-fA-F])$/
const RGB_COLOR_REGEX =
    /^rgb\(\s*(?<r>\d+)\s*,\s*(?<g>\d+)\s*,\s*(?<b>\d+)\s*\)$/
const RGBA_COLOR_REGEX =
    /^rgba\(\s*(?<r>\d+)\s*,\s*(?<g>\d+)\s*,\s*(?<b>\d+)\s*,\s*(?<a>(\d+|\d+\.\d+|\.\d+))\s*\)$/

const WHITE_RGB: RGB = {r: 255, g: 255, b: 255}

interface RGB {
    r: number;
    g: number;
    b: number;
}

function parseHex(color: string): RGB | null {
    const match = HEX_COLOR_REGEX.exec(color)

    if (!match) {
        return null
    }

    const r = parseInt(match[1], 16)
    const g = parseInt(match[2], 16)
    const b = parseInt(match[3], 16)

    return {r, g, b}
}

function parseShortHex(color: string): RGB | null {
    const match = SHORT_HEX_COLOR_REGEX.exec(color)

    if (!match) {
        return null
    }

    const r = parseInt(match[1] + match[1], 16)
    const g = parseInt(match[2] + match[2], 16)
    const b = parseInt(match[3] + match[3], 16)

    return {r, g, b}
}

function parseRgbColor(color: string): RGB | null {
    const match = RGB_COLOR_REGEX.exec(color) || RGBA_COLOR_REGEX.exec(color)

    if (!match) {
        return null
    }

    const r = parseInt(match[1])
    const g = parseInt(match[2])
    const b = parseInt(match[3])

    return {r, g, b}
}

export function calculateContrastColor(color?: string | null) {
    if (!color) {
        return '#000000'
    }

    const {r, g, b} = parseHex(color) || parseShortHex(color) || parseRgbColor(color) || WHITE_RGB

    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF'
}
