import {computed, createApp, h, provide, ref} from "vue"
import App from "./App.vue"
import 'primevue/resources/themes/aura-light-green/theme.css'
import "./main.css"
import {ApolloClients, provideApolloClient} from "@vue/apollo-composable"
import {createApolloClient} from "./graphql/apollo-client"
import router from "./router"
import Notifications from "notiwind"
import {loadNavigatorLocale} from "./date"
import {createLogger, LoggerKey} from "./logger"
import {provideAppContext} from "./AppContext"
import {plugin} from "@formkit/vue"
import formkitConfig from "./formkit.config"
import {Uploads, UploadsKey} from "./upload/Uploads"
import {createHead, useHead} from "@unhead/vue"
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import zitadelAuth from "./zitadelAuth";

const logger = createLogger()

declare module 'vue' {
  interface ComponentCustomProperties {
    $zitadel: typeof zitadelAuth
  }
}

zitadelAuth.oidcAuth.startup()
  .then(ok => {
    console.debug("Zitadel oidc auth startup: ", ok)

    if (ok) {
      const app = createApp({
        setup() {
          provide(LoggerKey, logger)
          const token = computed(() => zitadelAuth.oidcAuth.accessToken)

          const apolloClient = createApolloClient(token)
          provide(ApolloClients, {default: apolloClient})
          provideApolloClient(apolloClient)

          const uploads = new Uploads(token, apolloClient, logger)
          provide(UploadsKey, uploads)

          useHead({
            titleTemplate: '%s - NFans Redaktion',
          })

          // const user = computed(() => result.value?.me)

          const hasUnreadNotifications = ref(false)

          // watch(token, (newToken) => {
          //   logger.debug("Token changed, refetch Me", {token: newToken})
          //   refetch()
          // })
          // watch(error, (newError, previousError) => {
          //   if (newError) {
          //     logger.error("Error occurred in GetMe query", newError)
          //   }
          //
          //   if (newError && newError !== previousError) {
          //     notifyError({
          //       error: newError,
          //       description:
          //         "Konnte Benutzer nicht bestimmen, möglicherweise ist die Anmeldung abgelaufen.",
          //     })
          //   }
          // })

          provideAppContext({hasUnreadNotifications})
        },
        render: () => h(App as never),
      })

      app.config.globalProperties.$zitadel = zitadelAuth

      Sentry.init({
        app,
        dsn: "https://864417a54e4262df32a79828c8ba0689@o253654.ingest.sentry.io/4506522758545408",
        tracePropagationTargets: ["localhost", /^https:\/\/nfb\.lins\.io\//],
        integrations: [
          Sentry.browserTracingIntegration({router}),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        trackComponents: true,
        tracesSampleRate: 0.01,
        // Session Replay
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
      });

      app.use(router)
      app.use(createHead())
      app.use(Notifications)
      app.use(plugin, formkitConfig)
      app.use(PrimeVue);
      app.directive('tooltip', Tooltip);

      // Zuerst sicherstellen, dass das Browser locale geladen wurde
      loadNavigatorLocale()
        .then(
          (locale) => {
            logger.debug(`Loaded navigator locale ${locale}`)
          },
          (reason) => {
            logger.warn(`Failed to load a proper locale: ${reason}`)
          },
        )
        .finally(() => {
          // anschließend die App "starten"
          app.mount("#app")
        })
    } else {
      console.error('Startup was not ok')
    }
  })
