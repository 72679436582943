import {
  mdiCardAccountDetailsOutline,
  mdiCards,
  mdiCogs,
  mdiCrownOutline,
  mdiDomain,
  mdiFileDocumentMultiple,
  mdiFolderMultipleImage,
  mdiGamepadVariant,
  mdiNintendoGameBoy,
  mdiPacMan,
  mdiTagMultiple,
} from "@mdi/js"
import {Role} from "../graphql/types";

export function getPrivilegeIconAndTitle(privilege: Role | string): { icon: string | null; title: string | null } {
  const icon = getPrivilegeIcon(privilege);
  const title = getPrivilegeTitle(privilege);

  return {icon, title};
}

const ROLE_ORDER = [
  Role.MANAGE_ARTICLES,
  Role.MANAGE_GAMES,
  Role.MANAGE_GAME_SERIES,
  Role.MANAGE_GENRES,
  Role.MANAGE_GAME_CONSOLES,
  Role.MANAGE_COMPANIES,
  Role.MANAGE_MEDIA,
  Role.MANAGE_TAG,
  Role.MANAGE_RATING_CATEGORIES,
  Role.MANAGE_AUTHORS,
  Role.SYSTEM_ADMINISTRATION,
  Role.ACTUATOR,
]

export function comparePrivilege(a: Role, b: Role): number {
  const aPrio = ROLE_ORDER.indexOf(a)
  const bPrio = ROLE_ORDER.indexOf(b)

  if (-1 === aPrio || -1 === bPrio || a === b) return a.localeCompare(b)
  else return aPrio - bPrio
}

export function getPrivilegeIcon(privilege: Role | string): string | null {
  switch (privilege) {
    case Role.MANAGE_ARTICLES:
      return mdiFileDocumentMultiple;
    case Role.MANAGE_AUTHORS:
      return mdiCardAccountDetailsOutline;
    case Role.MANAGE_RATING_CATEGORIES:
      return mdiCrownOutline;
    case Role.MANAGE_GAMES:
      return mdiGamepadVariant;
    case Role.MANAGE_GAME_SERIES:
      return mdiCards;
    case Role.MANAGE_GENRES:
      return mdiPacMan;
    case Role.MANAGE_GAME_CONSOLES:
      return mdiNintendoGameBoy;
    case Role.MANAGE_COMPANIES:
      return mdiDomain;
    case Role.MANAGE_MEDIA:
      return mdiFolderMultipleImage;
    case Role.MANAGE_TAG:
      return mdiTagMultiple;
    case Role.SYSTEM_ADMINISTRATION:
      return mdiCogs;
    default:
      return null;
  }
}

export function getPrivilegeTitle(privilege: Role | string): string | null {
  switch (privilege) {
    case Role.MANAGE_ARTICLES:
      return "Darf Artikel verwalten";
    case Role.MANAGE_AUTHORS:
      return "Darf Autoren verwalten";
    case Role.MANAGE_RATING_CATEGORIES:
      return "Darf Bewertungskriterien verwalten";
    case Role.MANAGE_GAMES:
      return "Darf Spiele verwalten";
    case Role.MANAGE_GAME_SERIES:
      return "Darf Spielserien verwalten";
    case Role.MANAGE_GENRES:
      return "Darf Genres verwalten";
    case Role.MANAGE_GAME_CONSOLES:
      return "Darf Konsolen verwalten";
    case Role.MANAGE_COMPANIES:
      return "Darf Unternehmen verwalten";
    case Role.MANAGE_MEDIA:
      return "Darf Medien verwalten";
    case Role.MANAGE_TAG:
      return "Darf Schlagworte / Tags verwalten";
    case Role.SYSTEM_ADMINISTRATION:
      return "Darf System verwalten";
    default:
      return privilege;
  }
}
