import { computed, inject, provide, ref, Ref } from "vue"
import { MediaListItemFragment } from "../graphql/types"
import {UnwrapRef} from "@vue/reactivity"

export type VIEW = "list" | "grid"

const MediaListContextKey = Symbol("MediaListContext")

export interface MediaListContextData {
  scale: Ref<string>
  view: Ref<VIEW>
  selectedItems: Ref<Array<MediaListItemFragment>> | Ref<Array<UnwrapRef<MediaListItemFragment>>>
  isSingleSelect: Ref<boolean>
  isMultiSelect: Ref<boolean>
}

export function provideMediaListContext(data: MediaListContextData) {
  provide(MediaListContextKey, data)
}

export function useMediaListContext(): MediaListContextData {
  const data = inject(MediaListContextKey)

  if (!data) {
    throw new Error("MediaListContext missing")
  }

  return data as MediaListContextData
}
