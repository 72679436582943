import { Subject, Subscription } from "rxjs"
import { UploadImpl } from "./UploadImpl"
import { MediaListItemFragment } from "../graphql/types"
import { inject, Ref } from "vue"
import { Logger } from "../logger"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client/core"

export enum UploadPhase {
  FAILED,
  PREPARING,
  CREATING,
  UPLOADING,
  COMPLETE,
}

export interface Upload {
  readonly name: string
  readonly file?: File
  readonly size: number
  readonly state: Subject<UploadState>
}

export interface UploadState {
  /**
   * The upload phase.
   */
  readonly phase: UploadPhase;

  /**
   * The progress from 0 (no progress) to 1 (complete).
   */
  readonly progress?: number;
  readonly bytes?: number

  readonly error?: string;

  readonly media?: MediaListItemFragment
}

export class Uploads {
  private uploads = new Subject<Upload>()

  constructor(
    private token: Ref<string | null>,
    private graphqlClient: ApolloClient<NormalizedCacheObject>,
    private log: Logger,
  ) {
  }

  upload(file: File): Upload {
    const upload = new UploadImpl(file, this.token, this.graphqlClient, this.log)
    this.uploads.next(upload)
    return upload
  }

  subscribe(observer: (value: Upload) => void): Subscription {
    return this.uploads.subscribe(observer)
  }
}

export const UploadsKey = Symbol("Uploads")

export function useUploads(): Uploads {
  return inject(UploadsKey) as Uploads
}
