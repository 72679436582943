<script setup lang="ts">
import { calculateContrastColor } from "../color"
import { computed, toRefs } from "vue"
import SvgIcon from "@jamescoyle/vue-icon"
import {mdiTagTextOutline} from "@mdi/js"

const props = withDefaults(defineProps<{
  element?: string,
  tag: {
    text: string,
    color?: string,
    contrastColor?: string
  },
  icon?: boolean,
  dense?: boolean
}>(), {
  element: 'span'
})

const {element, tag} = toRefs(props)

const text = computed(() => tag.value.text)
const color = computed(() => tag.value.color)
const contrastColor = computed(() => tag.value.contrastColor || calculateContrastColor(color.value))
</script>

<template>
  <component
    :is="element"
    class="inline-flex items-center gap-2"
    :class="[dense ? 'px-2 py-1 text-xs font-normal rounded' : 'px-4 py-1 rounded-md']"
    :style="{ color: contrastColor, backgroundColor: color }"
  >
    <slot name="pre" />
    <slot name="icon" :tag="tag">
      <svg-icon
        v-if="icon"
        type="mdi"
        :path="mdiTagTextOutline"
        :class="[dense ? 'w-4 h-4' : 'w-5 h-5']"
      />
    </slot>
    <slot name="between" />
    <slot :tag="tag">
      {{ text }}
    </slot>
    <slot name="post" />
  </component>
</template>
