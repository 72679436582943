<script setup lang="ts">
import {computed, ref} from "vue"
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"
import {Bars3BottomLeftIcon, BellIcon, XMarkIcon} from "@heroicons/vue/24/outline"
import {MagnifyingGlassIcon} from "@heroicons/vue/24/solid"
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiCardAccountDetailsOutline,
  mdiCards,
  mdiCogs,
  mdiCrownOutline,
  mdiDomain,
  mdiFileDocumentMultiple,
  mdiFolderMultipleImage,
  mdiGamepadVariant,
  mdiHome,
  mdiNintendoGameBoy,
  mdiPacMan,
  mdiTagMultiple,
} from "@mdi/js"
import SvgIcon from "@jamescoyle/vue-icon"
import {getActiveHead} from "unhead"
import {useAppContext} from "../AppContext"
import {RouteLocationNormalized, useRoute} from "vue-router"
import Uploads from "../upload/Uploads.vue"
import zitadelAuth from "../zitadelAuth";
import {Role} from "../graphql/types";

const currentRoute = useRoute()

const navigation = [
  {
    name: "Dashboard",
    to: {name: "home"},
    icon: mdiHome,
  }
]

if (zitadelAuth.hasRole(Role.MANAGE_ARTICLES)) {
  navigation.push({
    name: "Beiträge",
    to: {name: "article-list"},
    icon: mdiFileDocumentMultiple,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_GAMES)) {
  navigation.push({
    name: "Spiele",
    to: {name: "game-list"},
    icon: mdiGamepadVariant,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_GAME_SERIES)) {
  navigation.push({
    name: "Spieleserien",
    to: {name: "game-series-list"},
    icon: mdiCards,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_GENRES)) {
  navigation.push({
    name: "Genres",
    to: {name: "genre-list"},
    icon: mdiPacMan,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_GAME_SERIES)) {
  navigation.push({
    name: "Spielkonsolen",
    to: {name: "game-console-list"},
    icon: mdiNintendoGameBoy,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_COMPANIES)) {
  navigation.push({
    name: "Unternehmen",
    to: {name: "company-list"},
    icon: mdiDomain,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_MEDIA)) {
  navigation.push({
    name: "Dateien",
    to: {name: "media-list"},
    icon: mdiFolderMultipleImage,
  });
}   

if (zitadelAuth.hasRole(Role.MANAGE_TAG)) {
  navigation.push({
    name: "Schlagworte / Tags",
    to: {name: 'tag-list'},
    icon: mdiTagMultiple,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_RATING_CATEGORIES)) {
  navigation.push({
    name: "Bewertungskategorien",
    to: {name: 'rating-category-list'},
    icon: mdiCrownOutline,
  });
}

if (zitadelAuth.hasRole(Role.MANAGE_AUTHORS)) {
  navigation.push({
    name: "Autoren",
    to: {name: "author-list"},
    icon: mdiCardAccountDetailsOutline,
  });
}

if (zitadelAuth.hasRole(Role.SYSTEM_ADMINISTRATION)) {
  navigation.push({
    name: "System",
    to: {name: "system"},
    icon: mdiCogs,
  });
}

function isParentOf(route: RouteLocationNormalized): boolean {
  return currentRoute.fullPath.startsWith(route.fullPath + "/")
}

const sidebarOpen = ref(false)

const {title} = getActiveHead()

const {hasUnreadNotifications} = useAppContext()

const username = computed(() => {
  if (zitadelAuth.oidcAuth.isAuthenticated) {
    const userProfile = zitadelAuth.oidcAuth.userProfile;
    return userProfile.preferred_username
  } else {
    return "Unbekannt️"
  }
})

const usernameInitial = computed(() => {
  if (zitadelAuth.oidcAuth.isAuthenticated) {
    const userProfile = zitadelAuth.oidcAuth.userProfile;
    const initials = [userProfile.given_name?.toString() ?? "", userProfile.family_name?.toString() ?? ""]
      .filter(s => !!s)
      .map((s: string) => s.substring(0, 1).toUpperCase())

    if (initials.length > 2) {
      initials.splice(1, initials.length - 2)
    }

    if (initials.length) {
      return initials.join("")
    }

    return userProfile.preferred_username.substring(0, 1).toUpperCase()
  } else {
    return "😶‍🌫️"
  }
})

function logout() {
  zitadelAuth.oidcAuth.signOut()
}
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 z-40 flex md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-600 bg-opacity-75"
          />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 px-4 flex items-center">
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                alt="Workflow"
              >
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.to"
                  custom
                >
                  <a
                    :href="href"
                    :class="[
                      isActive
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-base font-medium',
                    ]"
                    @click="navigate"
                  >
                    <svg-icon
                      type="mdi"
                      :path="item.icon"
                      :class="[
                        isActive
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'mr-4 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="border-r border-gray-200 bg-gray-900 pt-5 flex flex-col flex-grow overflow-y-auto"
      >
        <div
          class="flex-shrink-0 px-4 flex items-center justify-center"
        >
          <img
            class="h-8 w-auto"
            src="../assets/nfans_logo_invert.svg"
            alt="NFans"
          >
        </div>
        <div class="flex-grow mt-5 flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              v-slot="{ href, route, navigate, isActive }"
              :to="item.to"
              custom
            >
              <a
                :href="href"
                :class="[
                  isActive || isParentOf(route)
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-400 hover:bg-gray-50 hover:text-gray-900',
                  'group rounded-md py-2 px-2 flex items-center text-sm font-medium',
                ]"
                @click="navigate"
              >
                <svg-icon
                  type="mdi"
                  :path="item.icon"
                  :class="[
                    isActive || isParentOf(route)
                      ? 'text-gray-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <div class="md:pl-64 md:mr-24">
      <div class="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
        <div
          class="sticky top-0 z-50 flex-shrink-0 h-16 border-b border-gray-200 flex -mx-1 px-1 bg-gray-50"
        >
          <button
            type="button"
            class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
          <div class="flex-1 flex justify-between px-4 md:px-0">
            <div class="flex-1 flex">
              <form
                class="w-full flex md:ml-0"
                action="#"
                method="GET"
              >
                <label for="search-field" class="sr-only">
                  Search
                </label>
                <div
                  class="relative w-full text-gray-400 focus-within:text-gray-600"
                >
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  >
                    <MagnifyingGlassIcon
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search-field"
                    class="block h-full w-full border-transparent py-2 pl-8 pr-3 bg-gray-50 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                  >
                </div>
              </form>
            </div>
            <div class="ml-4 flex items-center md:ml-6">
              <Uploads/>

              <button
                type="button"
                :class="[
                  hasUnreadNotifications
                    ? ''
                    : 'pointer-events-none',
                  'p-1 ml-3 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative',
                ]"
              >
                <span class="sr-only">View notifications</span>
                <span class="flex h-6 w-6 relative">
                  <!--                  <BellIcon v-if="hasUnreadNotifications"-->
                  <!--                            class="animate-ping absolute inline-flex h-6 w-6 text-sky-400 opacity-75" aria-hidden="true"/>-->
                  <BellIcon
                    :class="[
                      hasUnreadNotifications
                        ? 'text-sky-500 hover:text-sky-300'
                        : 'text-gray-200',
                      'h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                </span>
                <!--
                <span v-if="hasUnreadNotifications" class="flex h-3 w-3 absolute bottom-1 right-1">
                  <span class="animate-ping absolute inline-flex rounded-full h-full w-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                </span>
                -->
              </button>

              <!-- Profile dropdown -->
              <Menu as="div" class="ml-3 relative group">
                <div
                  class="opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity absolute top-0 right-1/2 pl-4 pr-8 h-8 bg-white rounded-l-full pointer-events-none whitespace-nowrap flex items-center z-0"
                >
                  <span>{{ username }}</span>
                </div>
                <div>
                  <MenuButton
                    class="relative max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 z-1"
                  >
                    <span class="sr-only">
                      Open user menu
                    </span>
                    <svg
                      id="svg5"
                      class="h-8 w-8 rounded-full"
                      width="256"
                      height="256"
                      viewBox="0 0 67.733332 67.733335"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs id="defs2"/>
                      <g id="layer1">
                        <circle
                          style="fill: #696969; fill-opacity: 1;"
                          cx="33.866669"
                          cy="33.866669"
                          r="32.543751"
                        />
                        <text
                          id="text5770"
                          style="font-style: normal; font-weight: normal; font-size: 26px; line-height: 1.25; font-family: sans-serif; text-align: center; text-anchor: middle; fill: #fffff6; fill-opacity: 1; stroke: none; stroke-width: 1.0806;"
                          x="33.866665"
                          y="43.625015"
                        >
                          {{ usernameInitial }}
                        </text>
                      </g>
                    </svg>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                  >
                    <MenuItem v-slot="{ active }">
                      <router-link
                        v-slot="{ href, navigate }"
                        :to="{ name: 'profile' }"
                        custom
                      >
                        <a
                          :href="href"
                          :class="[
                            active
                              ? 'bg-gray-100'
                              : '',
                            'block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100',
                          ]"
                          @click="navigate"
                        >
                          Profil
                        </a>
                      </router-link>
                    </MenuItem>
                    <MenuItem>
                      <button @click="logout()" class="block w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                        Abmelden
                      </button>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1">
          <div class="py-6">
            <div class="px-4 sm:px-6 md:px-0">
              <h1 class="text-2xl font-semibold text-gray-900">
                {{    title }}
              </h1>
            </div>
            <div class="px-4 sm:px-6 md:px-0">
              <router-view/>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
