<script setup lang="ts">
import {FormKitNode, getNode} from "@formkit/core"
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue"
import {useLinkEditorContext} from "./LinkEditorContext"
import {onMounted, ref, toRefs, watchEffect} from "vue"

const props = defineProps<{
  open: boolean
}>()

const {open} = toRefs(props)

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'input', href?: string, target?: string, rel?: string): void
}>()

const rels = [
  'nofollow',
  'sponsored',
  'ugc',
]

const {
  href,
  target,
  rel,
} = useLinkEditorContext()

const formNode = ref<FormKitNode>()

onMounted(() => {
  formNode.value = getNode('link-editor')
})

watchEffect(() => {
  formNode.value?.reset({
    href: href.value,
    targetIsBlank: '_blank' === target.value,
    rel: rel.value.split(/\s+/),
  })
})

function onSubmit(data: { href: string, targetIsBlank: boolean, rel: string[] }) {
  emit('input', data.href, data.targetIsBlank ? '_blank' : undefined, data.rel.join(' '))
}
</script>

<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog :open="open" @close="emit('close')" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <FormKit id="link-editor" type="form" :value="{ href, target, rel }" @submit="onSubmit">
                <FormKit
                  type="url"
                  name="href"
                  label="Web URL"
                />
                <FormKit
                  type="checkbox"
                  name="targetIsBlank"
                  label="In neuem Fenster/Tab öffnen"
                />
                <FormKit
                  type="taglist"
                  name="rel"
                  label="Rel"
                  :options="rels"
                  :allow-new-values="true"
                />
              </FormKit>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
