import {createZITADELAuth} from "@zitadel/vue";
import {LogLevel, SignInType} from 'vue-oidc-client/vue3';
import {User, WebStorageStateStore} from "oidc-client";

const zitadelAuth = createZITADELAuth(
  {
    project_resource_id: ZITADEL_PROJECT_RESOURCE_ID,
    client_id: ZITADEL_CLIENT_ID,
    issuer: ZITADEL_BASE_URL,
  },
  "zitadel",
  SignInType.Popup,
  `${window.location.origin}/`,
  {
    userStore: new WebStorageStateStore({ store: window.localStorage })
  },
  console,
  LogLevel.Debug,
)

zitadelAuth.hasRole = (role: string) => {
  const roles = zitadelAuth.oidcAuth.userProfile[`urn:zitadel:iam:org:project:roles`] as Record<string, any>
  if (!roles) {
    return false
  }
  return !!roles[role]
}

// handle events
zitadelAuth.oidcAuth.events.addAccessTokenExpiring(function () {
  // eslint-disable-next-line no-console
  console.log('access token expiring')
})

zitadelAuth.oidcAuth.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line no-console
  console.log('access token expired')
})

zitadelAuth.oidcAuth.events.addSilentRenewError(function (err: Error) {
  // eslint-disable-next-line no-console
  console.error('silent renew error', err)
})

zitadelAuth.oidcAuth.events.addUserLoaded(function (user: User) {
  // eslint-disable-next-line no-console
  console.log('user loaded', user)
})

zitadelAuth.oidcAuth.events.addUserUnloaded(function () {
  // eslint-disable-next-line no-console
  console.log('user unloaded')
})

zitadelAuth.oidcAuth.events.addUserSignedOut(function () {
  // eslint-disable-next-line no-console
  console.log('user signed out')
})

zitadelAuth.oidcAuth.events.addUserSessionChanged(function () {
  // eslint-disable-next-line no-console
  console.log('user session changed')
})

export default zitadelAuth
