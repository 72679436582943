<script setup lang="ts">
import {Editor} from "@tiptap/vue-3"
import SvgIcon from "@jamescoyle/vue-icon"
import {
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatParagraph,
  mdiHelpCircleOutline,
  mdiRedo,
  mdiUndo,
} from "@mdi/js"
import {onBeforeUnmount, onMounted, ref} from "vue"

const props = defineProps<{
  editor: Editor
}>()

const emit = defineEmits<{
  (e: 'help'): void
}>()

const editorToolbar = ref<HTMLElement>()
const isToolbarFloating = ref(false)

function onScroll() {
  isToolbarFloating.value = editorToolbar.value?.offsetTop > 0
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <div
    class="flex gap-1 items-center -mt-2 mb-2 -mx-3 py-2 px-3 bg-gray-50 rounded-t sticky top-16 z-10"
    :class="{ 'elevation-2 mx-n9 px-9': isToolbarFloating }" v-if="editor"
    ref="editorToolbar"
  >
    <button
      type="button"
      class="rounded"
      @click="editor.chain().focus().undo().run()"
      :disabled="!editor.can().undo()"
    >
      <SvgIcon type="mdi" :path="mdiUndo"/>
    </button>
    <button
      type="button"
      class="rounded"
      @click="editor.chain().focus().redo().run()"
      :disabled="!editor.can().redo()">
      <SvgIcon type="mdi" :path="mdiRedo"/>
    </button>
    <span class="mx-2"></span>
    <button
      type="button"
      @click="editor.chain().focus().setParagraph().run()"
      class="rounded"
      :class="editor.isActive('paragraph') ? 'text-teal-600 hover:text-grey-600' : 'text-grey-600 hover:text-teal-600'">
      <SvgIcon type="mdi" :path="mdiFormatParagraph"/>
    </button>
    <button
      type="button"
      @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      class="rounded"
      :class="editor.isActive('heading', { level: 2 }) ? 'text-teal-600 hover:text-grey-600' : 'text-grey-600 hover:text-teal-600'">
      <SvgIcon type="mdi" :path="mdiFormatHeader2"/>
    </button>
    <button
      type="button"
      @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      class="rounded"
      :class="editor.isActive('heading', { level: 3 }) ? 'text-teal-600 hover:text-grey-600' : 'text-grey-600 hover:text-teal-600'">
      <SvgIcon type="mdi" :path="mdiFormatHeader3"/>
    </button>
    <button
      type="button"
      @click="editor.chain().focus().toggleBulletList().run()"
      class="rounded"
      :class="editor.isActive('bulletList') ? 'text-teal-600 hover:text-grey-600' : 'text-grey-600 hover:text-teal-600'">
      <SvgIcon type="mdi" :path="mdiFormatListBulleted"/>
    </button>
    <button
      type="button"
      @click="editor.chain().focus().toggleOrderedList().run()"
      class="rounded"
      :class="editor.isActive('orderedList') ? 'text-teal-600 hover:text-grey-600' : 'text-grey-600 hover:text-teal-600'">
      <SvgIcon type="mdi" :path="mdiFormatListNumbered"/>
    </button>
    <span class="mx-2"></span>
    <button
      type="button"
      @click="emit('help')"
      class="text-grey-600 hover:text-teal-600"
    >
      <SvgIcon type="mdi" :path="mdiHelpCircleOutline"/>
    </button>
  </div>
</template>
