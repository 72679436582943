import {rootClasses} from './formkit.theme.ts'
import {createInput, defaultConfig, defineFormKitConfig} from "@formkit/vue"
import {de, FormKitLocaleRegistry} from '@formkit/i18n'
import {genesisIcons, icons as defaultIcons} from '@formkit/icons'
import {mdiAccount, mdiHome, mdiHomeCircle, mdiWeb} from "@mdi/js"
import {
  autocomplete,
  colorpicker,
  createProPlugin,
  datepicker,
  dropdown,
  mask,
  rating,
  repeater,
  slider,
  taglist,
  toggle,
  togglebuttons,
  transferlist,
} from '@formkit/pro'
import MediaPicker from "./inputs/MediaPicker.vue"
import TipTap from "./inputs/TipTap.vue"

function pathToSvg(path: string): string {
  return `<svg viewBox="0 0 24 24" class="w-full"><path fill="currentColor" d="${path}" /></svg>`
}

const icons: Record<string, string | undefined> = {
  ...defaultIcons,
  ...genesisIcons,
  account: pathToSvg(mdiAccount),
  web: pathToSvg(mdiWeb),
  home: pathToSvg(mdiHome),
  homeCircle: pathToSvg(mdiHomeCircle),
}
const locales: FormKitLocaleRegistry = {de: de}
const locale = 'de'

const inputs = {
  mediapicker: createInput(MediaPicker),
  tiptap: createInput(TipTap, {
    family: 'text'
  })
}

const pro = createProPlugin('fk-4fec92e16c', {
  autocomplete,
  colorpicker,
  datepicker,
  dropdown,
  mask,
  rating,
  repeater,
  slider,
  taglist,
  toggle,
  togglebuttons,
  transferlist,
})
const plugins = [pro]

export default defaultConfig(defineFormKitConfig({
  config: {
    rootClasses,
  },
  icons,
  locales,
  locale,
  inputs,
  plugins,
})())
