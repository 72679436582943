<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"
import TipTapHelpTextFormats from "./TipTapHelpTextFormats.vue"
import TipTapHelpBlockFormats from "./TipTapHelpBlockFormats.vue"
import TipTapHelpSymbols from "./TipTapHelpSymbols.vue"
import TipTapHelpEmojis from "./TipTapHelpEmojis.vue"

const props = defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'input', text: string): void
}>()
</script>

<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog :open="open" @close="emit('close')" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[800px] transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <TabGroup>
                <TabList class="flex space-x-1 mb-4 rounded-xl bg-blue-900 p-1">
                  <Tab v-slot="{ selected }" as="template">
                    <button
                      type="button"
                      :class="[
                        'w-full rounded-lg p-2.5 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                      ]"
                    >
                      Textformat
                    </button>
                  </Tab>
                  <Tab v-slot="{ selected }" as="template">
                    <button
                      type="button"
                      :class="[
                        'w-full rounded-lg p-2.5 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                      ]"
                    >
                      Blockformat
                    </button>
                  </Tab>
                  <Tab v-slot="{ selected }" as="template">
                    <button
                      type="button"
                      :class="[
                        'w-full rounded-lg p-2.5 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                      ]"
                    >
                      Zeichen
                    </button>
                  </Tab>
                  <Tab v-slot="{ selected }" as="template">
                    <button
                      type="button"
                      :class="[
                        'w-full rounded-lg p-2.5 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-blue-700 shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                      ]"
                    >
                      Emojis
                    </button>
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <TipTapHelpTextFormats/>
                  </TabPanel>
                </TabPanels>
                <TabPanel>
                  <TipTapHelpBlockFormats/>
                </TabPanel>
                <TabPanel>
                  <TipTapHelpSymbols/>
                </TabPanel>
                <TabPanel>
                  <TipTapHelpEmojis @input="(input) => emit('input', input)"/>
                </TabPanel>
              </TabGroup>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
