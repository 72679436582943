<script setup lang="ts">

import {computed, ref} from "vue"
import zitadelAuth from "../zitadelAuth";
import {useAllPrivileges} from "../privileges";

const allPrivileges = useAllPrivileges()
const isAuthenticated = computed(() => zitadelAuth.oidcAuth.isAuthenticated)
const preferredUsername = computed(() => zitadelAuth.oidcAuth.userProfile.preferred_username)
const name = computed(() => {
    const userProfile = zitadelAuth.oidcAuth.userProfile;
  return [userProfile.given_name?.toString() ?? "", userProfile.family_name?.toString() ?? ""].join(" ").trim()
})
const hasAnyRole = computed(() => allPrivileges.value.some(zitadelAuth.hasRole))

const isProcessing = ref(false)

function login() {
  isProcessing.value = true
  zitadelAuth.oidcAuth.signIn()
    .finally(() => isProcessing.value = false)
}

function logout() {
  zitadelAuth.oidcAuth.signOut()
}
</script>
<template>
  <div class="min-h-screen flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-24 w-auto mx-auto" src="../assets/nfans_logo_invert.svg" alt="NFans Admin">
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900 text-center">
            Melde dich an
          </h2>
        </div>

        <div v-if="isAuthenticated && !hasAnyRole" class="mt-8">
          <div class="text-red-600 mb-4 text-center">
            Sorry <em>{{ name }} ({{ preferredUsername }})</em>,<br>aber du hast nicht genug Rechte.
          </div>
          <button
            type="submit"
            :disabled="isProcessing"
            :class="[
              isProcessing
                ? 'bg-indigo-300 animate-pulse'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white'
            ]"
            @click="logout() "
          >
            Abmelden
          </button>
        </div>

        <div v-else class="mt-12">
          <button
            type="submit"
            :disabled="isProcessing"
            :class="[
              isProcessing
                ? 'bg-indigo-300 animate-pulse'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white'
            ]"
            @click="login() "
          >
            Mit Nintendofans.de SSO anmelden
          </button>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="../assets/2689846.jpg"
        alt=""
      >
    </div>
  </div>
</template>
