<script setup lang="ts">
import TipTapHelpSymbolTable from "./TipTapHelpSymbolTable.vue"
import {SymbolDefinition} from "./format"

const symbols: SymbolDefinition[] = [
  {
    symbol: 'Dash <code>—</code>',
    input: '<code>--</code>',
  },
  {
    symbol: 'Ellipsis <code>…</code>',
    input: '<code>...</code>',
  },
  {
    symbol: 'Pfeil links <code>←</code>',
    input: '<code>&lt;-</code>',
  },
  {
    symbol: 'Pfeil rechts <code>→</code>',
    input: '<code>-&gt;</code>',
  },
  {
    symbol: 'Copyright <code>©</code>',
    input: '<code>(c)</code>',
  },
  {
    symbol: 'Registered Trademark <code>®</code>',
    input: '<code>(r)</code>',
  },
  {
    symbol: 'Trademark <code>™</code>',
    input: '<code>(tm)</code>',
  },
  {
    symbol: 'Ein Halb <code>½</code>',
    input: '<code>1/2</code>',
  },
  {
    symbol: 'Ein Viertel <code>¼</code>',
    input: '<code>1/4</code>',
  },
  {
    symbol: 'Drei Viertel <code>¾</code>',
    input: '<code>3/4</code>',
  },
  {
    symbol: 'Plus-Minus <code>±</code>',
    input: '<code>+/-</code>',
  },
  {
    symbol: 'Ungleich <code>≠</code>',
    input: '<code>!=</code>',
  },
  {
    symbol: 'Zitat Marke links <code>«</code>',
    input: '<code>&lt;&lt;</code>',
  },
  {
    symbol: 'Zitat Marke rechts <code>»</code>',
    input: '<code>&gt;&gt;</code>',
  },
  {
    symbol: 'Multiplikation <code>2×3</code>',
    input: '<code>2*3</code> oder <code>2x3</code>',
  },
  {
    symbol: 'Quadrat <code>²</code>',
    input: '<code>^2</code>',
  },
  {
    symbol: 'Kubik <code>³</code>',
    input: '<code>^3</code>',
  },
]
</script>

<template>
  <TipTapHelpSymbolTable label="Symbol" :symbols="symbols"/>
</template>
