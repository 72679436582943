<script setup lang="ts">
import {emojis} from '@tiptap-pro/extension-emoji'
import {computed, ref} from "vue";
import {EmojiItem} from "@tiptap-pro/extension-emoji/dist/tiptap-pro/packages/extension-emoji/src/emoji";

const emit = defineEmits<(e: 'input', text: string) => void>()

const query = ref("")

const groupedEmojis = computed<Record<string, Array<EmojiItem>>>(() => {
  const queryValue = query.value.toLowerCase()

  const result: Record<string, Array<EmojiItem>> = {}
  for (let emoji of emojis) {
    if (
      !queryValue ||
      emoji.name.toLowerCase().includes(queryValue) ||
      emoji.shortcodes.some(shortcode => shortcode.includes(queryValue))
    ) {
      const group = emoji.group ?? ""
      if (!result[group]) {
        result[group] = [emoji]
      } else {
        result[group].push(emoji)
      }
    }
  }
  return result
})
</script>

<template>
  <div class="space-y-4 text-xl">
    <div>
      <input type="text" v-model="query" class="block w-full text-sm rounded" placeholder="Suche&hellip;"/>
    </div>
    <div v-for="(groupEmojis, groupName) of groupedEmojis" :key="groupName">
      <div class="text-xs font-bold mb-4">{{ groupName }}</div>
      <div class="flex flex-wrap items-stretch gap-1">
        <template v-for="(item, index) of groupEmojis" :key="index">
          <button v-if="item.emoji" type="button" class="group relative p-1 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 hover:ring focus:ring ring-indigo-200 transition-all rounded aspect-square w-10" @click="emit('input', item.emoji)">
            <span>{{ item.emoji }}</span>
            <code class="absolute pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity top-full left-1/2 translate-x-[-50%] rounded p-1 text-xs bg-black text-white z-10">:{{ item.shortcodes.join(': :') }}:</code>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
