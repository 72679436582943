<script setup lang="ts">
import {mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiFormatUnderline} from "@mdi/js"
import {FormatDefinition} from "./format"
import TipTapHelpFormatTable from "./TipTapHelpFormatTable.vue"

const formats: FormatDefinition[] = [
  {
    icon: mdiFormatBold,
    format: 'Fett <code>&lt;strong&gt;</code>',
    shortcut: '<code>**Text**</code> oder <code>__Text__</code>',
    keys: [['Strg', 'B']],
  },
  {
    icon: mdiFormatItalic,
    format: 'Kursiv <code>&lt;em&gt;</code>',
    shortcut: '<code>*Text*</code> oder <code>_Text_</code>',
    keys: [['Strg', 'I']],
  },
  {
    icon: mdiFormatUnderline,
    format: 'Unterstrichen <code>&lt;u&gt;</code>',
    shortcut: '',
    keys: [['Strg', 'U']],
  },
  {
    icon: mdiFormatStrikethrough,
    format: 'Durchgestrichen <code>&lt;s&gt;</code>',
    shortcut: '<code>~~Text~~</code>',
    keys: [['Strg', 'Shift', 'X']],
  },
  {
    format: 'Hervorheben <code>&lt;mark&gt;</code>',
    shortcut: '<code>==Text==</code>',
    keys: [['Strg', 'Shift', 'H']],
  },
  {
    format: 'Tiefgestellt <code>&lt;sub&gt;</code>',
    shortcut: '',
    keys: [['Strg', ',']],
  },
  {
    format: 'Hochgestellt <code>&lt;sup&gt;</code>',
    shortcut: '',
    keys: [['Strg', '.']],
  },
  {
    format: 'Zeilenumbruch <code>&lt;br&gt;</code>',
    shortcut: '',
    keys: [['Shift', 'Enter'], ['Strg', 'Enter']],
  },
]
</script>

<template>
  <TipTapHelpFormatTable :formats="formats"/>
</template>
