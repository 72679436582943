import {inject, provide, Ref} from "vue"
import {MeFragment} from "./graphql/types";

const AppContextKey = Symbol('AppContext')

export interface AppContextData {
    hasUnreadNotifications: Ref<boolean>
}

export function provideAppContext(data: AppContextData) {
    provide(AppContextKey, data)
}

export function useAppContext(): AppContextData {
    const data = inject(AppContextKey);

    if (!data) {
        throw new Error('AppContext missing')
    }

    return data as AppContextData
}
